import React from 'react'
import { useHistory } from "react-router-dom";
const PageNotFound = () => {
  const history = useHistory();
  return (
    <div className='error-404-page text-center mt-5'>
      <img src="../img/404.jpg" className='img-responsive mw-500 mb-3'/>
      <br />
      <button  data-font-size-mo="14" data-font-size-pc="16" onClick={e=>history.push(process.env.REACT_APP_HOME_ROUTE)}>Back to home</button>
    </div>

  )
}

export default PageNotFound