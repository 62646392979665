import React from 'react'
import { Helmet } from "react-helmet";


const HelmetComp = (props) => {
    let { data, title, defaultName, defaultContent, titleWithoutSuffix } = props
    return (
        <Helmet>
            {titleWithoutSuffix ? <title>{title}</title> : <title>{title} &mdash; Digital Service Center | Samsung.com</title>}
            {data && data.length ? data.map((item, i) => {
                if (item.MetaName) return <meta key={i} name={item.MetaName || ''} content={item.MetaContent || ''} />
                else if (item.HttpEquiv) return <meta httpEquiv={item.HttpEquiv || ''} content={item.MetaContent || ''} />
            }) :
                <meta name={defaultName || ''} content={defaultContent || ''} />
            }
        </Helmet>
    )
}

export default HelmetComp