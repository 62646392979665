import React from 'react'
import { PopUp } from './../../Library';
export const RegistrationModal = (props) => {
    let userDetails = props.userData && props.userData.userData && props.userData.userData.userDetails && props.userData.userData.userDetails ? props.userData.userData.userDetails : {};
    return (
        <div className="modal hidden registration" id='registrationModal'>
            <h2 className="modal__header search-product-card__title">
                Registration{" "}
            </h2>
            <form className="modal__form">
                <label>First Name</label>
                <input aria-hidden="false" title='First Name' className="form-control" type="text" placeholder="First Name" value={userDetails.given_name || ""} disabled />
                <label>Last Name</label>
                <input aria-hidden="false" title='Family Name' className="form-control " type="text" placeholder="First Name" value={userDetails.family_name || ""} disabled />
                <label>Email Address</label>
                <input aria-hidden="false" title='Email ID' className="form-control " type="text" placeholder="Email ID" value={userDetails.email || ""} name='email'  disabled/>
                <label>Mobile Number</label>
                <input aria-hidden="false" title='Mobile No.' className="form-control " type="text" placeholder="Mobile Number" name="mobileNumber" value={userDetails.mobileNumber || ""} onChange={props.handleMobNum} />
            </form>
            <div className="align-center margin-top--32 margin-bottom--16">
                <a className="cta cta--outlined cta--black close-modal" href="" onClick={(e) => { e.preventDefault(); PopUp(false, 'registrationModal'); }} > Cancel </a>
                <a className="cta cta--contained cta--black ml-2" href="" onClick={props.handleFormSubmit}>Submit</a>
            </div>
        </div>
    )
}