import React from "react";
import Slider from "react-slick";
import MagicSliderDots from 'react-magic-slider-dots';


const QuickLinksComp = (props) => {
  const settings = {
    dots: props.isMobile ? false : true,
    arrows: props.isMobile ? false : true,
    infinite: false,
    speed: 500,
    variableWidth: true,
    adaptiveHeight: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        }
      },
    ],
    appendDots: dots => {
      return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={20} />;
    }
  };
  let quicklinks = props.quicklinks || {}
  let quicklinksChild = quicklinks.ChildMenus || []
  let exploreserviceChild = props.exploreservice && props.exploreservice.ChildMenus ? props.exploreservice.ChildMenus : []
  let selectedProductSolution = props.selectedProductSolution || {}
  let selectedProduct = props.selectedProduct || {}
  let childMenus = props.productPage ? [...quicklinksChild, ...exploreserviceChild] : quicklinksChild
  return (
    <>
      <div className={`su-g-search-product-card aem-GridColumn aem-GridColumn--default--12  ${props.isMobile ? "show-mobile bg-gray" : "show-desktop"}`}>
        <div className={`search-product-card ${props.isMobile ? "" : "bg-gray padding-narrow padding-bottom--48"}`}>
          <div className="search-product-card__support">
            <h2 className="search-product-card__title" data-font-size-mo="34">
              {props.productPage ? (quicklinks && quicklinks.ParentMenuTitle) || "" : (quicklinks && quicklinks.ParentMenuName)}
            </h2>
            {!props.productPage &&
              <div className="search-product-card__description show-desktop">
                {(quicklinks && quicklinks.ParentMenuSubTitle) || ""}
              </div>}
            <ul className={`search-product-card__item-list quicklinksguid   ${props.isMobile && props.productPage ? "product-quick-links show-mobile" : props.isMobile ? "quick-links mo-q-links  mobile-scroll" : "quick-links pc-q-links"}`}>
              {!(props.isMobile && props.productPage) ?
                <Slider {...settings}  >
                  {childMenus.length > 0 ? childMenus.map((item, index) => {
                    if ((selectedProductSolution && (Object.keys(selectedProductSolution).length) && item.ChildCatogoryCode.includes(selectedProductSolution.ChildCatogoryCode))
                      || (selectedProduct && (Object.keys(selectedProduct).length) && item.ChildCatogoryCode.includes(selectedProduct.CIC_PRD))
                      || (!(Object.keys(selectedProductSolution).length) && !(Object.keys(selectedProduct).length) && props.isMobile && item.ChildCatogoryCode.includes(process.env.REACT_APP_HOMEMOB))
                      || (!(Object.keys(selectedProductSolution).length) && !(Object.keys(selectedProduct).length) && !props.isMobile && item.ChildCatogoryCode.includes(process.env.REACT_APP_HOMEWEB))
                    ) {
                      return <li key={item.ChildMenuID} className={item.ChildMenuRedirectURL == "/bookappointment" ? 'bookappointment' : item.ChildMenuRedirectURL == "/pickdrop" ? 'pickdrop' : ''}>
                        <a
                          href=''
                          target="_self"
                          className={"search-product-card__item"}
                          onClick={e => { props.handleLogging('', item, index + 1, 'QuickLinks'); props.handleIntExtRoute(e, { ...item, sourceName : quicklinks.ParentMenuName }) }}
                        >
                          <strong className="search-product-card__item-text">
                            {item.ChildMenuTitle || ''}
                          </strong>
                          <div className="image image--main-loaded">
                            <img
                              className="image__main responsive-img image--loaded"
                              src={item.ChildMenuIcon || ''}
                              alt={item.ChildMenuTitle}
                            />
                          </div>
                        </a>
                      </li>
                    }
                  }) : <></>}

                </Slider>
                : <>
                  {childMenus && childMenus.length > 0 ? childMenus.map((item, index) => {
                    if (selectedProductSolution && (Object.keys(selectedProductSolution).length) && item.ChildCatogoryCode.includes(selectedProductSolution.ChildCatogoryCode) || (selectedProduct && (Object.keys(selectedProduct).length) && item.ChildCatogoryCode.includes(selectedProduct.CIC_PRD)) || (!selectedProductSolution || (selectedProductSolution && (!Object.keys(selectedProductSolution).length))) && (!selectedProduct || (selectedProduct && (!Object.keys(selectedProduct).length)))) {
                      return <li key={item.ChildMenuID}>
                        <a
                          href=''
                          target="_self"
                          className="search-product-card__item"
                          onClick={e => { props.handleLogging('', item, index + 1, 'QuickLinks'); props.handleIntExtRoute(e, { ...item, sourceName : quicklinks.ParentMenuName }) }}
                        >
                          <strong className="search-product-card__item-text">
                            {item.ChildMenuTitle || ''}
                          </strong>
                          <div className="image image--main-loaded">
                            <img
                              className="image__main responsive-img image--loaded"
                              src={item.ChildMenuIcon || ''}
                              alt={item.ChildMenuTitle}
                            />
                          </div>
                        </a>
                      </li>
                    }
                  }) : <></>}
                </>}
            </ul>
            {props.isMobile && !props.productPage &&
              <div className="explore-more align-center pb-1">
                <a className="cta cta--outlined cta--black explore-more" href="#" onClick={e => e.preventDefault()}  >
                  Explore more
                </a>
              </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default QuickLinksComp;
