import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
// import { PopUp } from '@Core/Library'
import actions from "../../Redux/Action";
import SignInModal from '../CommnPages/SignInModal'
const SupportSystem = (props) => {
  const history = useHistory();
  const dispatch = useDispatch()
  let SelfhelpData = useSelector((state) => state.productReducer.SelfhelpData) || {};
  let state = history && history.location && history.location.state;
  let routData = {
    "path": process.env.REACT_APP_SELFHELP_ROUTE,
    "SelfhelpData": {...SelfhelpData,'category':props.categoryData &&  props.categoryData.ChildMenuID || '', 'selectedCategory': props.categoryData||{},},
  }
  const handleRedirect = (e) => {
    e && e.preventDefault();
    if (!props.isLoggedIn) {
      props.handleSignInModle(true,routData)
      return
    } else {
      history.push(process.env.REACT_APP_SELFHELP_ROUTE, state);
      props.handleScroll()
    }
    dispatch(actions.CommonActions.SetSelfhelpData({ ...SelfhelpData, subCategoryDataList: [], productDataList: [], SHStep: 1, selectedIssues: {}, selectedSupportOption: '', scheduleReqHistory: 'newRequest', error: {}, RescheduleAppData: {}, chatbot: false, category: '' }))
    dispatch(actions.CommonActions.setProductIssueSubIssue({}))
    props.handleLogging(e, { ChildMenuRedirectURL: '/selfhelp', ChildMenuName: "Selfhelp" }, 1, 'Onload Selfhelp')

    // history.push(process.env.REACT_APP_SELFHELP_ROUTE, state)
  }
  
  
  return (
    <>
      {props.productPage ? 
          <div className='home-video-section-bx mt-2'>
            <div className='row align-items-center'>
            <div className='col-xs-12 col-md-12 pl-md-5 text-center'>
                <h2 className='form__title mb-0 mb-mt-5 pt-3 mt-2 mt-md-0 text-center' data-font-size-mo="18" data-font-size-pc="32">Get Customized Support</h2>
                <p data-font-size-mo="12" data-font-size-pc="16" className='mb-md-0 pb-md-0'>Get help, DIY videos, FAQs or connect with an expert.</p>
              </div>
              <div className='col-xs-12 col-md-12 text-center'>
                <img className='img-responsive my-2'  src="img/chatbot/Support_image.png" alt='video section' style={{ mixBlendMode: "darken",maxHeight:"150px",minHeight:"150px" }} />
              </div>
              
              <div className='col-xs-12 col-md-12 text-center'>
                <a className="next cta cta--contained cta--black cta--outlined cta--icon my-2" href="" onClick={e => handleRedirect(e)}>Click Here</a>
              </div>
            </div>
      </div>
      :
      <div className='home-video-section mb-5 mt-5 pb-4'>
      <div className='main-container'>
        <div className='home-video-section-bx'>
          <div className='row align-items-center'>
            <div className='col-xs-12 col-md-3 text-center text-md-left'>
              <img className='img-responsive main-thumbnail-img' src="img/chatbot/Support_image.png" alt='video section' />
            </div>
            <div className='col-xs-12 col-md-6 pl-md-5 text-center text-md-left'>
              <h2 className='form__title mb-0 mb-mt-4 mt-3 mt-md-0 text-center text-md-left' data-font-size-mo="18" data-font-size-pc="32">Get Customized Support</h2>
              <p data-font-size-mo="12" data-font-size-pc="16" className='mb-md-0 pb-md-0'>Get help, DIY videos, FAQs or connect with an expert.</p>
            </div>
            <div className='col-xs-12 col-md-3 text-center'>
              <a className="next cta cta--contained cta--black cta--outlined cta--icon" href="" onClick={e => { handleRedirect(e); props.handleLogging(e, { ChildMenuRedirectURL: '/diy', ChildMenuName: "DIY" }, 1, 'Onload DIY') }}>Click Here</a>
            </div>
          </div>
        </div>
        </div>
        </div>
      }
      {/* <SignInModal  {...props} routData={routData} /> */}
    </>
  )
}

export default SupportSystem
