import React, { useRef, useState, useEffect } from "react";
import UseHeight from "@Core/Components/UseHeight";
import SearchBox from "./SearchBox";
function kFormatter(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
}

const SearchContainer = (props) => {

  const elementDOM = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const height = UseHeight(elementDOM, loaded);

  const handleLoad = () => {
    setLoaded(true);
  };
  let userDetails =
    props.userData &&
      props.userData.userData &&
      props.userData.userData.userDetails &&
      props.userData.userData.userDetails
      ? props.userData.userData.userDetails
      : {};
  let searchLinks = props && props.searchLinks || {}
  return (
    <React.Fragment>
      <div className="main-container navbar-fixed">
        {/* <!--Search HEADER  --> */}
        <section className="search-kv">
          <div className="search-kv__container" style={!props.isMobile ? { height: `${height}px` } : {}}>
            {/* <!-- Search Content  --> */}
            <div className="search-kv__contents">
              {props.isLoggedIn ? (
                <>
                  <p
                    className="search-kv__eyebrow-text show-desktop"
                    data-font-size-pc="24"
                  >
                    {" "}
                    {`Welcome ${userDetails.given_name || ""} ${userDetails.family_name || ""
                      }`}
                  </p>
                  <p
                    className="search-kv__eyebrow-text show-mobile"
                    data-font-size-mo="28"
                  >
                    {" "}
                    {`Welcome ${userDetails.given_name || ""} ${userDetails.family_name || ""
                      }`}{" "}
                  </p>
                  <h2 className="search-kv__title show-desktop">
                    {" "}
                    Digital Service Center
                  </h2>
                  <h2
                    className="search-kv__title show-mobile"
                    data-font-size-mo="44"
                  >
                    {" "}
                    Digital Service Center{" "}
                  </h2>
                </>
              ) : (
                <>
                  <p
                    className="search-kv__eyebrow-text show-desktop"
                    data-font-size-pc="24"
                  >
                    We`re here for you
                  </p>
                  <h2 className="search-kv__title show-desktop">
                    Welcome to Digital Service Center
                  </h2>
                  <h2
                    className="search-kv__title show-mobile"
                    data-font-size-mo="34"
                  >
                    Digital Service Center
                  </h2>
                  <div className="show-desktop">
                    <p className={`search-kv__eyebrow-text margin-top--16  ${!props.isMobile ? 'signinguid' : ''}`}>
                      For personalized experience
                      <a
                        className="cta cta--contained cta--white cta--icon ml-2"
                        href=""
                        onClick={props.handleLoggin}
                      >
                        Sign In
                      </a>
                    </p>
                  </div>
                </>
              )}

              <div className="search-kv__image">
                <div className="image image--main-loaded get-image-height">

                  <img
                    ref={elementDOM}
                    onLoad={handleLoad}
                    alt="hero-banner"
                    className="image__main responsive-img image--loaded show-desktop"
                    src={props.isMobile ? `img/search/Home_KV_1440x500.jpg` : `img/search/Home_KV_1440x500.jpg`} />

                </div>
              </div>
            </div>
            {/* <!-- Search Box  --> */}
            <div className="search-kv__search-wrap">
              <SearchBox {...props} />

              <div className="search-kv__keyword  show-desktop">
                <p className="hidden">related search</p>
                <ul className="search-kv__keyword-list">
                  {searchLinks &&
                    searchLinks.ChildMenus &&
                    searchLinks.ChildMenus.length > 0 ? (
                    searchLinks.ChildMenus.map((item, index) => {
                      return (
                        <li className="search-kv__keyword-list-item" key={item.ChildMenuID} >
                          <a
                            href={item.ChildMenuRedirectURL || ""}
                            className="chip chip__action--medium chip__action--dark"
                            title="customer care"
                            role="button"
                            an-tr="su07_search kv-support main-cta-tag"
                            an-ca="search"
                            an-ac="support search bar"
                            an-la="keyword:customer care"
                            target={item.ChildMenuType == 1 ? '_blank' : ''}
                            onClick={e => props.handleLogging('', item, index + 1, 'SearchLinks')}
                          >
                            {item.ChildMenuTitle || ""}
                          </a>
                        </li>
                      );
                    })
                  ) : (
                    <></>
                  )}


                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <!-- Sign In For Mobile  --> */}
      {props.isMobile && !props.isLoggedIn && <SignInMobile {...props} />}
      <LoyalityCard {...props} />
    </React.Fragment>
  );
};

const SignInMobile = (props) => {
  return (
    <div className="su-g-search-product-card aem-GridColumn aem-GridColumn--default--12 show-mobile signinguid">
      <div className="sign-in-container">
        <div className="cl-7">
          <h3 className="sign-in__title" data-font-size-mo="34">
            <div>For personalized experience</div>
          </h3>
        </div>
        <div className="cl-5 text-end">
          <a
            className="cta cta--contained cta--black"
            href=""
            onClick={props.handleLoggin}
          >
            {" "}
            Sign In{" "}
          </a>
        </div>
      </div>
    </div>
  );
};
const LoyalityCard = (props) => {
  let loyaltyDetails = props.userData && Object.keys(props.userData).length && props.userData.loyaltyDetails ? props.userData.loyaltyDetails : {};
  let CustomerTier = 'Plus'//loyaltyDetails.CustomerTier //'Plus'
  let TotalSpend = loyaltyDetails.TotalSpend //15000
  let EliteThreshold = parseFloat(loyaltyDetails.EliteThreshold)
  let ProThreshold = parseFloat(loyaltyDetails.ProThreshold)
  let PlusThreshold = parseFloat(loyaltyDetails.PlusThreshold)
  let loyaltyThreshold = TotalSpend >= ProThreshold ? EliteThreshold : TotalSpend >= PlusThreshold ? ProThreshold : PlusThreshold
  let loyaltyPercentage = ((TotalSpend / EliteThreshold) * 100) > 100 ? 100 : ((TotalSpend / EliteThreshold) * 100)
  let loyaltyRemainingPoints = (loyaltyThreshold - TotalSpend)
  let NextThreshold = loyaltyThreshold == EliteThreshold ? 'Elite' : loyaltyThreshold == ProThreshold ? 'Pro' : 'Plus'
  useEffect(() => {
    const progressValue = document.querySelector('.Progressbar__value') && document.querySelector('.Progressbar__value');
    const progress = document.querySelector('progress') && document.querySelector('progress');
    if (progress) {

      progressValue.style.width = `${progress.value}%`;
      progress.value = progress.value;
    }
  })
  return (
    <>
      {props.isLoggedIn && loyaltyDetails.TotalBalancePoints ? (
        <div className="gnb-strip__center">
          <div className="strip-layout ">
            {!props.isMobile &&
              <h4 className="title-mssg">
                Say hello to exclusive rewards!
              </h4>
            }

            <div className="loyality-strip-container">
              <div className="image-cards">
                <div className="pts-center">
                  <h4 className="card-title">{kFormatter(loyaltyDetails.TotalBalancePoints || 0)} Pts</h4>
                  <h5 className="loyality-card-title ">{CustomerTier} </h5>
                </div>
                <img src={`/img/loyality-card/${CustomerTier}.jpg`} alt="card" />
              </div>
              <div className="loyality-card ">
                <div className="progress-box">
                  <span className="plus-dot"></span>
                  <span className="pro-dot"></span>
                  <span className="elite-dot"></span>
                  <div className="Progressbar">
                    <div className="Progressbar__value"></div>
                    <progress value={loyaltyPercentage} max="100">100%</progress>
                  </div>
                </div>
                <div className="strip-container">
                  {TotalSpend < loyaltyThreshold && <h5 className="card-status">Spend INR {kFormatter(loyaltyRemainingPoints)} more to reach {NextThreshold}</h5>}
                  <a
                    href="https://www.samsung.com/in/microsite/smart-club/"
                    className="mem-link "
                    target="_blank"
                    onClick={e => props.handleLogging('', { ChildMenuRedirectURL: "https://www.samsung.com/in/microsite/smart-club/" }, 1, ' Loyalty Member Benefit')}
                  >
                    Benefit{" "}
                    <img
                      src="/img/menu/mem-link-black.svg"
                      className="icon"
                      alt="mem-link-black"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : ''}
    </>
  )
}

export default SearchContainer;
