import React from 'react'

const MobQuickTips = (props) => {
  let solutionstips = (props && props.solutionstips) || {};
  return (
    <div> 
        {/* <!-- Help Me to Choose for Desktop --> */}
    <div className="su-g-search-product-card aem-GridColumn aem-GridColumn--default--12 quick-tips-mobil-view">
     <div className="search-product-card ">
       <h2 className="su11-link-card__headline-text margin-top--32">Quick Tips</h2>
       <div className="search-product-card__support">
         <div className="co35-showcase-card-tab co35-showcase-card-tab--merchandizing bg-white">
           <div className="co35-showcase-card-tab__inner co35-showcase-card-tab__inner--without-merchandizing-tab">
             <div className="co35-showcase-card-tab__card-wrap">
               <div className="co35-showcase-card-tab__card-items">
                 <div className="co35-showcase-card-tab-inner-container" role="list">

                 {solutionstips &&
                    solutionstips.ChildMenus &&
                    solutionstips.ChildMenus.length > 0 ? (
                      solutionstips.ChildMenus.map((item, index) => {
                        return (<div key={item.ChildMenuID} className="co35-showcase-card-tab-card co35-showcase-card-tab-card--small co35-showcase-card-tab-card__text-color--black mobile-text-only "
                          role="listitem">
                          <a className={`co35-showcase-card-tab-card__full-bleed-wrap card-${index + 1
                              }`} target="_self"
                              href={item.ChildMenuRedirectURL || ""}
                              onClick={e=>props.handleLogging('',item,index+1,'QuickTips')}>
                            <div className="co35-showcase-card-tab-card__product-name-text-wrap">
                              <div className="co35-showcase-card-tab-card__product-name-text-inner">
                                <span
                                  className="co35-showcase-card-tab-card__product-name co35-showcase-card-tab-card__product-name--desktop"> {item.ChildMenuTitle || ""}</span>
                                <span
                                  className="co35-showcase-card-tab-card__product-name co35-showcase-card-tab-card__product-name--mobile"> {item.ChildMenuTitle || ""}</span>
                              </div>
                            </div>
                            <div className="co35-showcase-card-tab-card__img-wrap">
                              <div an-tr="co35_showcase card-support main-cta-banner" an-ca="content click"
                                className="image image--main-loaded">
                                <img className="image__main responsive-img image--loaded"
                                  src={item.ChildMenuIcon || ""}  alt={item.ChildMenuTitle}/>
                              </div>
                            </div>
                            <div className="co35-showcase-card-tab-card__product-cta">
                              <span className="cta cta--contained cta--black cta--icon">
                                Learn more
                              </span>
                            </div>
                          </a>
                        </div>
                   );
                  })
                ) : (
                  <></>
                )}
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
   </div>
  )
}

export default MobQuickTips