import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import actions from './../../Redux/Action';
import { toast } from 'react-toastify';
import jQuery from 'jquery';
const SearchBox = (props) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const history = useHistory();
    const dispatch = useDispatch();
    let search = useSelector((state) => state.productReducer.search) || {}
    let [state, setState] = useState({})

    const handleSearch = (e) => {
        const { name, value } = e.target;
        setState({ [name]: value });
    };
    const clearSearchData = () => {
        //setState({ searchTerm: '' });
        setSearchTerm('');
    }


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm.trim() !== '') {
                fetchSearchResults(searchTerm);
            }
            else {
                setSearchResults([]);
            }
        }, 800);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    const fetchSearchResults = (value) => {
        //dispatch(actions.CommonActions.showLoader(true))
        let data = {
            SearchString: value
        }
        dispatch(actions.ServiceAction.GetSearchResults(data, (res) => {
            if (res.IsSuccess) {
                setSearchResults(res.Entity);
                showSearchBox();
            } else {
                toast.error(res.Message);
            }
            //dispatch(actions.CommonActions.showLoader(false))
        }))
    }

    const handleSearchChange = (e) => {
        const { value } = e.target;
        setSearchTerm(value);
    }


    const showSearchBox = () => {
        document.querySelector(
            ".search-kv__popular-searches-wrap"
        ).style.display = "block";
        document.querySelector(".search-kv__search-wrap")
            .classList.add("layer-popup");
        document.querySelector(".search-kv__form-wrap")
            .classList.add("is-active");
        document.querySelector(".search-kv__search-delete").style.display =
            "block";
        jQuery(".search-kv__no-searches-result").show();
    };
    return (
        <div className="searchBox search-kv__form-wrap ">
            <form action="#" className="search-kv__form" role="search">
                <fieldset>
                    <legend>Search form</legend>
                    <div className="search-kv__form-container ">
                        {/* <!-- Search Form  --> */}
                        <div className="text-field-wrap">
                            <div className="text-field text-field--hide-label">
                                <input
                                    aria-hidden="false"
                                    title='How can we help you?'
                                    id="search-kv"
                                    name="searchTerm"
                                    type="text"
                                    autoComplete="off"
                                    placeholder="How can we help you?"
                                    //onChange={handleSearch}
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                                <button type="button" className="search-kv__search-delete" onClick={clearSearchData}  >
                                    <span className="hidden">Delete</span>
                                    <img src="/img/svg-icons/cancel-bold.svg" alt="icon" />
                                </button>
                            </div>
                            <button type="button" className="search-kv__form-submit">
                                <img src="/img/svg-icons/search-bold.svg" alt="icon" />
                            </button>
                            <button type="button" className="search-kv__search-wrap-close"  >
                                <svg className="icon icon--back" xmlnsXlink="http://www.w3.org/2000/svg" viewBox="0 0 96 96" focusable="false"  >
                                    <path fill="#ffffff" d="M40.544 11.613l5.538 5.774L18.335 44l75.054.001v8H18.337l27.745 26.612-5.538 5.774L2.611 48l37.933-36.387z"></path>
                                </svg>
                                <span className="hidden">Back to Menu</span>
                            </button>
                        </div>
                        {/* <!-- Popular Searches  --> */}
                        <div className="search-kv__popular-searches-wrap">
                            {searchResults && searchResults.length > 0 &&
                                <p className="search-kv__searches-title title-popular">
                                    Popular Searches
                                </p>}
                            <ul className="search-kv__searches-list" role="listbox" id="popular">
                                {searchResults && searchResults.length > 0 ? searchResults.map((item) => {
                                    return (
                                        <li key={item.ID || ""}>
                                            <a id={item.Title.trim().toLowerCase()}
                                                className="search-kv__searches-link"
                                                href=""
                                                onClick={(e) => { props.handleIntExtRoute(e, item); clearSearchData(); props.handleLogging(e, { ...item, ChildMenuName: item.Title }, 1, 'Global Search') }}>
                                                <span className="search-kv__searches-text" role="text">
                                                    {item.Title || ""}
                                                </span>
                                            </a>
                                        </li>
                                    );
                                }) : ''}
                            </ul>

                        </div>
                        {/* <!-- Suggested Searches  --> */}
                        <div className="search-kv__suggested-searches-wrap">
                            <p className="search-kv__searches-title">
                                Suggested Searches
                            </p>
                            <ul
                                className="search-kv__searches-list"
                                role="listbox"
                            ></ul>
                        </div>
                        {/* <!-- No Result  --> */}
                        {(document.querySelector("#search-kv") && document.querySelector("#search-kv").value) ?
                            <div className="search-kv__no-searches-result  padding-top--0" style={{ 'display': document.querySelector("#search-kv").value ? 'block' : 'none' }}>
                                <a className="searchUrl" id="searchUrl"
                                    href={`https://www.samsung.com/in/search/?searchvalue=${document.querySelector("#search-kv") ? document.querySelector("#search-kv").value : ''}#support`}>
                                    <p className="search-kv__searches-title">
                                        Search Support :<span className="support-search">{`"${document.querySelector("#search-kv") ? document.querySelector("#search-kv").value : ''}"`} </span>
                                    </p>
                                    <ul
                                        className="search-kv__searches-list"
                                        role="listbox"
                                    ></ul>
                                </a>
                            </div> : ''}
                    </div>
                </fieldset>
            </form>
        </div>
    )
}

export default SearchBox