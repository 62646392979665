const Apipaths =  {
    BASE_URL : "",
    menu  : "/v1.0/menu",
    userProduct  : "/v1.0/GetUserDetails",
    userProductJSON  : "/userProduct.json",
    ssoconfig  : "/ssoconfig.json",
    signInUR1 : "https://account.samsung.com/v1/oidc/authorize?",
    signInURL : "https://account.samsung.com/accounts/v1/DSCWEB/signInGate?",
    SSOConfiguration : "/v1.0/SSOConfiguration",
    BPCREATE :  "/v1.0/CSC_BP_CREATE",
    getUserDetailsWithToken : '/v1.0/GetUserDetailsWithToken',
    insertLoggingApi : '/v1.0/InsertLoggingAPI',
    insertPickDropApi : '/v1.0/InsertPickDropApi',
    searchPinCode : '/v1.0/SearchPinCode',
    getModelNumber : '/v1.0/GetModelNumber',
    getModelNumberHHP : '/v1.0/GetModelNumberHHP',
    GetExistingPickDrop : '/v1.0/ExistingPickDropApi',
    GetState : '/v1.0/GetState',
    GetCity : '/v1.0/GetCity',
    GetServiseCenterList : '/v1.0/GetServiseCenterList',
    GetServiseCenterDetail : '/v1.0/GetServiseCenterDetail',
    GetTimeSlotByDate : '/v1.0/GetTimeSlotByDate',
    GetAppointmentDate : '/v1.0/GetAppointmentDate',
    GetExistingAppointments : '/v1.0/GetExistingAppointments',
    CancelAppointment : '/v1.0/CancelAppointment',
    CreateAppointment : '/v1.0/CreateAppointment',
    SaveAppointment : '/v1.0/SaveAppointment',
    ReAppointment : '/v1.0/ReAppointment',
    ValidateOTP : '/v1.0/ValidateOTP',
    SparePartResponse: '/v1.0/SparePartResponse',   
    SparePartPrice:'/v1.0/SparePartPrice',
    RepairPartsDetails: '/v1.0/GetRepair_Cost_Response',
    GetStateCity: '/v1.0/GetStateCity',
    GetVideos: '/v1.0/GetDIYData',
    insertReqSupport : '/v1.0/REST_GATA_CREATE',
    getTrackRepair: '/v1.0/CSC_TrackRepair',
    GetProductIssueSubIssue : '/v1.0/ProductIssueSubIssue',
    InsertUpdateAgentSchedule : '/v1.0/InsertUpdateAgentSchedule',
    GetTimeSlots : '/v1.0/GetTimeSlots',
    GetPromotionImages: '/v1.0/GetPromotionImages',
    GetAgentScheduleData : '/v1.0/GetAgentScheduleData',
    ChatBotAPI : '/v1.0/ChatBotAPI',
    GetGenerateOfferCode:'/v1.0/GetGenerateOfferCode',
    InsertSaveLater:'/v1.0/InsertSaveLater',
    GetSaveLater: '/v1.0/GetSaveLater',
    GetUserMasterDetail: '/v1.0/GetUserMasterDetails',
    InsertUserMasterDetails: '/v1.0/InsertUserMasterDetails',
    DeleteUserMasterAddress: '/v1.0/DeleteUserMasterAddress',
    GetSearchResults: '/v1.0/GetSearchResults',
    GetServiceCenterDetailsForCode : '/v1.0/GetServiceCenterDetailsForCode',
    UpdateUserConsent : '/v1.0/UpdateUserConsent',
    GetAppointmentDetailHistory : '/v1.0/GetExistingAppointmentDetailHistory',
    GetMetaTagMasterDetailsDSC : '/v1.0/GetMetaTagMasterDetailsDSC',
    GetCaptcha:'/v1.0/GetCaptcha',
    InsertMailQuestionFormDetails:'/v1.0/InsertMailQuestionFormDetails',
    GetAllTrackRepairDetails : '/v1.0/CSC_TrackRepairAll',
    GetGCICFeedbackQue :'/v1.0/GetGCICFeedback',
    GCICFeedbackSubmit :'/v1.0/GCICFeedbackSubmit',
    ExportPartWarranty : '/v1.0/ExportPartWarranty',
    LeadManagementGetUserListForLiveDemo:'/LeadManagementGetUserListForLiveDemo',
    GetCaptchaImage:'/GetCaptchaImage'
}
export default Apipaths
