import React from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "@Core/Components/Layout";
import MainComp from "Pages/Components/Home";
import product from "Pages/Components/Product";
import Landing from "Pages/Components/Landing";
//import Feedback from "Pages/Components/Feedback";
import PickDrop from "Pages/Components/Pick&Drop";
import BookAppointment from "Pages/Components/BookAppointment";
import SpareParts from "Pages/Components/SpareRepairParts";
import Diy from "Pages/Components/Diy";
import Estore from "Pages/Components/Estore";
import SelfHelp from "Pages/Components/SelfHelp";
import SignLang from "Pages/Components/Signlang";
import TrackDetails from "Pages/Components/TrackDetails";
import AppointmentDetail from "Pages/Components/BookAppointmentDetail";
import PageNotFound from "@Core/Components/PageNotFound";
import MailQuestionGeneralNps from "Pages/Components/MailQuestionForm/MailQuestionGeneralNps"
import MailQuestionGeneralNew from "Pages/Components/MailQuestionForm/MailQuestionGeneralNew";
import Econnect from "Pages/Components/EConnect";
const Routes = (props) => {
  return (
    <>
      <Switch>
      <Route
          exact
          path={process.env.REACT_APP_EKONNECT_ROUTE}
          render={(routerProps) => (
            <Layout
              Component={Econnect}
              {...routerProps}
            />
          )}
        />  
      <Route
          exact
          path={process.env.REACT_APP_MAILQESTIONGENERAL_NPS}
         component={MailQuestionGeneralNps}
        />
      <Route
          exact
          path={process.env.REACT_APP_MAILQESTIONGENERAL_NEW}
         component={MailQuestionGeneralNew}
        />   
      <Route
          exact
          path={process.env.REACT_APP_APPOINTMENT_DETAIL}
          render={(routerProps) => (
            <Layout
              Component={AppointmentDetail}
              {...routerProps}
            />
          )}
        /> 
      <Route
          exact
          path={process.env.REACT_APP_WARRANTYPOLICIES}
          render={(routerProps) => (
            <Layout
              Component={TrackDetails}
              {...routerProps}
            />
          )}
        /> 
        <Route
          exact
          path={process.env.REACT_APP_REQUESTSERVICE}
          render={(routerProps) => (
            <Layout
              Component={TrackDetails}
              {...routerProps}
            />
          )}
        /> 
        <Route
          exact
          path={process.env.REACT_APP_TRACKREPAIR}
          render={(routerProps) => (
            <Layout
              Component={TrackDetails}
              {...routerProps}
            />
          )}
        /> 
      <Route
          exact
          path={process.env.REACT_APP_SIGNLANG_ROUTE}
          render={(routerProps) => (
            <Layout
              Component={SignLang}
              {...routerProps}
            />
          )}
        />  
      <Route
          exact
          path={process.env.REACT_APP_SELFHELP_REMOTESERVICE_ROUTE}
          render={(routerProps) => (
            <Layout
              Component={SelfHelp}
              {...routerProps}
            />
          )}
        />  
        <Route
          exact
          path={process.env.REACT_APP_SELFHELP_ROUTE}
          render={(routerProps) => (
            <Layout
              Component={SelfHelp}
              {...routerProps}
            />
          )}
        />  
        <Route
          exact
          path={process.env.REACT_APP_ESTORE_ROUTE}
          render={(routerProps) => (
            <Layout
              Component={Estore}
              {...routerProps}
            />
          )}
        />  
        <Route
          exact
          path={process.env.REACT_APP_DIY_ROUTE}
          render={(routerProps) => (
            <Layout
              Component={Diy}
              {...routerProps}
            />
          )}
        />  
      <Route
          exact
          path={process.env.REACT_APP_REPAIRCOST_ROUTE}
          render={(routerProps) => (
            <Layout
              Component={SpareParts}
              {...routerProps}
            />
          )}
        />
        <Route
          exact
          path={process.env.REACT_APP_SPAREPART_ROUTE}
          render={(routerProps) => (
            <Layout
              Component={SpareParts}
              {...routerProps}
            />
          )}
        />
        <Route
          exact
          path={process.env.REACT_APP_BOOKAPPOINTMENT_ROUTE}
          render={(routerProps) => (
            <Layout
              Component={BookAppointment}
              {...routerProps}
            />
          )}
        />
        <Route
          exact
          path={process.env.REACT_APP_PICKDROP_ROUTE}
          render={(routerProps) => (
            <Layout
              Component={PickDrop}
              {...routerProps}
            />
          )}
        />
      <Route
          exact
          path={process.env.REACT_APP_PRODUCT_CATEGORY_ROUTE}
          render={(routerProps) => (
            <Layout
              Component={product}
              {...routerProps}
            />
          )}
        />
        <Route
          exact
          path={process.env.REACT_APP_PRODUCT_ROUTE}
          render={(routerProps) => (
            <Layout
              Component={product}
              {...routerProps}
            />
          )}
        />
        <Route
          exact
          path={process.env.REACT_APP_LANDING_ROUTE}
          render={(routerProps) => (
            <Layout
              Component={Landing}
              {...routerProps}
            />
          )}
        />
        <Route
         exact
          path="/"
          render={(routerProps) => (
            <Layout
              Component={MainComp}
              {...routerProps}
            />
          )}
        /> 
        <Route component={PageNotFound}/> 
      </Switch>   
    </>
  );
};

export default Routes;
