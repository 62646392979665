
import api from "@Core/Apis/api";
import apipaths from "@Core/Apis/apiPaths";

const URL=process.env.REACT_APP_SD_PROD_IP; //REACT_APP_SD_UAT_IP //REACT_APP_SD_PROD_IP


const EConnectAction = {
    LeadManagementGetUserListForLiveDemo: function (data,cb) {
      return (dispatch) => {
        api.setMethod("POST").sendRequest(URL+apipaths.LeadManagementGetUserListForLiveDemo, data, true, function (response) {
          dispatch({
            type: "LeadManagementGetUserListForLiveDemo",
            data: response.data.Entity ? response.data.Entity : {}
          })
          cb && cb(response.data)
        },
          dispatch
        );
      };
    },
    GetCaptchaImage:function (data,cb){
      return (dispatch)=>{
        api.setMethod("POST").sendRequest(URL+apipaths.GetCaptchaImage,data,true, function (response){
          dispatch({
            type:'GetCaptchaImage',
            data: response.data.Entity ? response.data.Entity : {}
          })
          cb && cb(response.data)
        },dispatch);
      }
    }
}


export default EConnectAction;