import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PopUp } from '@Core/Library'
const SignInModal = (props) => {
  //const dispatch = useDispatch();
  let { handleLoggin, hideCancelBtn,popupId} = props
  let id = popupId ? popupId :"signInPopUp" 
  const handlePopUP = (e, name) => {
    e.preventDefault();
    //if (name == 'close')  PopUp(false, 'signInPopUp')
    PopUp(false, id)
  }
  return (
    <>
      <div id={id} className="modal">
        <div className="modal-content">
          <div className="modal-body">
            {/* <img
              src="../img/svg-icons/success.svg"
              className="success-icon"
              alt="success"
            /> */}
            <h2 className="form__title padding-top--0 font-700 mb-3"
              data-font-size-mo="18" data-font-size-pc="28" >
              Please sign in for a personalized experience.
            </h2>
            {/* <p className='mb-4'>Get started with your samsung account</p> */}
            {/* <div className="search-product-card__description">
             
            </div> */}

            {/* <div className="search-product-card__description price-block">
              
            </div> */}
          </div>
          <div className="modal-footer">
          <a className=" cta cta--contained cta--black cta--icons mb-1"    href=""  onClick={e=>handleLoggin(e,props.routData)}  > {'Sign In'} </a>
          <br />
          {!hideCancelBtn && 
          <a className=" cta cta--contained cta--white cta--icon"  href="#" onClick={e => handlePopUP(e, 'close')}> {'Cancel'} </a>
          }
          </div>
        </div>
      </div>
    </>
  )
}

export default SignInModal
