const CommonFunction = {
  getBrowser: function () {
    let userAgent = navigator.userAgent.toLowerCase();
    let browserName
    if (userAgent.indexOf("edge") > -1) {
      browserName = "MS Edge";
    } else if (userAgent.indexOf("edg/") > -1) {
      browserName = "Edge (chromium based)";
    } else if (userAgent.indexOf("opr") > -1 && !!window.opr) {
      browserName = "Opera";
    } else if (userAgent.indexOf("chrome") > -1 && !!window.chrome) {
      browserName = "Chrome";
    } else if (userAgent.indexOf("trident") > -1) {
      browserName = "MS IE";
    } else if (userAgent.indexOf("firefox") > -1) {
      browserName = "Mozilla Firefox";
    } else if (userAgent.indexOf("safari") > -1) {
      browserName = "Safari";
    } else {
      browserName = "No browser detection";
    }
    return browserName
  },
  handleRedirect: function (e, path, params, method = 'post') {
    e && e.preventDefault()
    const form = document.createElement('form');
    form.method = method;
    form.action = path;
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = params[key];

        form.appendChild(hiddenField);
      }
    }
    document.body.appendChild(form);
    form.setAttribute("target", "_blank")
    form.submit();
  },
  get: function (key) {
    return sessionStorage.getItem(key) ? atob(sessionStorage.getItem(key)).replace(/\"/g, "") : null;
  },
  validateEmail: function (email) {
    return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,4}))$/);
  },
  capitalizeString: function (string) {
    return string && string.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  },
  tConvert: function (time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)/) || [time];
    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string 
  },
  dateFormatter: function (dateStr) {
    if (dateStr.includes("-")) {
      let dArr = dateStr.split("-");
      return dArr[2] + "/" + dArr[1] + "/" + dArr[0];
    }
    else {
      return dateStr;
    }
  },
  mobileFormatter: function (mob) {
    return mob.replace(/^.{1,7}/, m => "X".repeat(m.length))
  },
  addDays: function (date, days) {
    days = parseInt(days)
    let tempDate = new Date(date)
    tempDate.setDate(tempDate.getDate() + days)
    return tempDate
  },
  maskPhoneNumber: function(phoneNumber) {
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '***-***-$3');
  },
  maskEmail: function(email) {
    return email.replace(/(.)(.*)(?=@)/, (match, firstChar, rest) => firstChar + '*'.repeat(rest.length));
  },
}

export default CommonFunction