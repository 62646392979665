import React from "react";
import Slider from "react-slick";
import MagicSliderDots from 'react-magic-slider-dots';
const ProductSolutionsComp = (props) => {
  const settings = {
    dots: props.isMobile ? false  : true,
    arrows: props.isMobile ? false : true,
    infinite: false,
    speed: 500,
    variableWidth: true,
    adaptiveHeight: false,
    slidesToShow: 6,
    slidesToScroll:2,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        }
      },
    ],
    appendDots: dots => {
      return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={20} />;
    }
  };
  let productsolution = props.productsolution;
  return (
    <>
      <div
        className={`su-g-search-product-card aem-GridColumn aem-GridColumn--default--12 ${props.isMobile ? "show-mobile" : "show-desktop" }`}
      >
        <div className="search-product-card">
          <div className="search-product-card__support">
            <h2 className="search-product-card__title" data-font-size-mo="34">
            {(productsolution && productsolution.ParentMenuName) || ""}
            </h2>
            <div className="search-product-card__description show-desktop">
            {(productsolution && productsolution.ParentMenuSubTitle) || ""}
            </div>
            {/* <!-- Product Solution Slider --> */}
            <ul
              className={`productsolution search-product-card__item-list ${
                props.isMobile ? "mo-my-product mobile-scroll" : "desktop-product"
              } `}
            >
              
               <Slider {...settings}>
                   {productsolution && productsolution.ChildMenus &&  productsolution.ChildMenus.length >0 ? productsolution.ChildMenus.map((item,index)=>{
                      return <li key ={item.ChildMenuID}>
                        <a
                          href=""
                          className="search-product-card__item"
                          onClick={e=>{props.handleProductSol(e,item); props.handleLogging(e,item,index+1,'ProductSolutions')}}
                        >
                          <strong className="search-product-card__item-text">
                            {item.ChildMenuTitle}
                          </strong>
                          <div className="image image--main-loaded">
                            <img
                              className="image__main responsive-img image--loaded "
                              src={item.ChildMenuIcon}
                              alt= {item.ChildMenuTitle}
                            />
                          </div>
                        </a>
                      </li>
                    }): <></>}
                </Slider>
                
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSolutionsComp;
