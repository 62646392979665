import React, { Suspense, lazy } from "react";

import {Loading} from "@Core/Components/Loader";

const SDUserForm = lazy(() =>  import("./userform"));

const EconnectWraper = (props) => {
  return (
    <>
      <Suspense fallback={<Loading />}>
        <SDUserForm {...props} />
      </Suspense>
    </>
  );
};

export default EconnectWraper;