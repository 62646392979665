import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PopUp } from '@Core/Library'

const AddPoductMsgModal = (props) => {
  //const dispatch = useDispatch();
  let { handlePageRoute} = props
  const handlePopUP = (e) => {
    e.preventDefault();
    //if (name == 'close')  PopUp(false, 'signInPopUp')
    window.location.reload()
    PopUp(false, 'addPoductMsgModal')
  }
  return (
    <>
      <div id='addPoductMsgModal' className="modal">
        <div className="modal-content">
          <div className="modal-body">
            <h2 className="form__title padding-top--0 font-700 mb-3"
              data-font-size-mo="18" data-font-size-pc="28" >
              To view the recently added products, please refresh the page.
            </h2>
            
          </div>
          <div className="modal-footer">
          <a className=" cta cta--contained cta--black cta--icons mb-1"    href=""  onClick={e=>handlePopUP(e)}  > {'Refresh'} </a>
          <br />
         
          </div>
        </div>
      </div>
    </>
  )
}

export default AddPoductMsgModal
