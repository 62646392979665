import CommonActions from './CommonActions/commonAction';
import ServiceAction from './ServiceAction';
import EConnectAction from './EConnectActions';


const Action ={
    CommonActions,
    ServiceAction,
    EConnectAction
}
export default Action