import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
// import { PopUp } from '@Core/Library'
import actions from '../../Redux/Action';
import SignInModal from 'Pages/CommnPages/SignInModal';

const MobContactUs = (props) => {
  const dispatch = useDispatch();
  let EmailUsFlag = useSelector((state) => state.productReducer.EmailUsFlag) || false
  const hiddenAnchorRef = useRef(null);
  let userDetails =
    props.userData &&
      props.userData.userData &&
      props.userData.userData.userDetails &&
      props.userData.userData.userDetails
      ? props.userData.userData.userDetails
      : {};
  let partnerCode = props.userData && props.userData.partnerCode || '';
  let routData = {
    "path": process.env.REACT_APP_HOME_ROUTE,
    "EmailUsFlag": true,
    "navMenu": 'contactUs'
  }
  // useEffect(() => {
  //   if (EmailUsFlag && Object.keys(userDetails).length) {
  //     if (hiddenAnchorRef.current) {
  //       hiddenAnchorRef.current.click();
  //     }
  //     dispatch(actions.CommonActions.SetEmailUsFlag(false))
  //   }
  // }, [userDetails])
  useEffect(() => {
    if (EmailUsFlag && Object.keys(userDetails).length) {
      setTimeout(() => {
        if (hiddenAnchorRef.current) {
          hiddenAnchorRef.current.click();
        }
        dispatch(actions.CommonActions.SetEmailUsFlag(false))
      },4000)
    }
    if (props.queryParam && props.queryParam.action && props.queryParam.action == 'email') {
      if (!props.token) {
        // PopUp(true, 'signInPopUpEmailUs')
        props.handleSignInModle(true,routData)
      } else if (props.isLoggedIn && Object.keys(userDetails).length) {
        // PopUp(false, 'signInPopUpEmailUs')
        props.handleSignInModle(false,{})
        if (hiddenAnchorRef.current) {
          hiddenAnchorRef.current.click();
        }
      }
      // setImmediate(() => {
      //   dispatch(actions.CommonActions.showLoader(false))
      // })
    }
  }, [userDetails])
  const handlecheckLogin = (e) => {
    e.preventDefault();
    if (!props.isLoggedIn) {
      // PopUp(true, 'signInPopUpEmailUs')
      props.handleSignInModle(true,routData)
      return
    } else {
      if (hiddenAnchorRef.current) {
        hiddenAnchorRef.current.click();
      }
    }
  }

 
  return (
    <React.Fragment>
      {/* <!-- MAIN CONTENT  --> */}
      <div className="main-container navbar-fixed">
        {/* <!--Search HEADER  --> */}
        <section className="search-kv">
          <div className="search-kv__container prdouct-search">
            {/* <!-- Search Content  --> */}
            <div className="search-kv__contents">
              <h2 className="search-kv__title show-desktop">We`re here for you</h2>
              <h2 className="search-kv__title show-mobile" data-font-size-mo="44">We`re here for you</h2>
              <div className="search-kv__image">
                <div className="image image--main-loaded get-image-height">
                  <img className="image__main responsive-img image--loaded show-desktop" src="/img/search/Home_KV_1440x500.jpg" alt='Hero-banner' />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- Contact Detail  --> */}
      <div className="of-g-feature-benefit-card aem-GridColumn aem-GridColumn--default--12 padding-bottom--48 ma">
        <div className="feature-benefit-card padding-top--48 margin-bottom--normal bg-white feature-benefit-card--desktop-layout-4">
          <h2 className="su11-link-card__headline-text ">Contact Info</h2>
          <div className="su11-link-card__list su11-link-card__swiper">
            <div className="su11-link-card__list-wrap ">
              <div className="su11-link-card__list-item card-visible">
                <div className="su11-link-card__list-item-wrap">
                  <h3 className="su11-link-card__list-item-title">
                    WhatsApp(English / हिंदी)
                    <div className="su11-link-card__list-item-icon">
                      <div className="image image--main-loaded">
                        <img className="image__main responsive-img image--loaded" src="/img/contact/Whatsapp.png" alt='whatsapp' />
                      </div>
                    </div>
                  </h3>
                  <span className="su11-link-card__list-item-description">WhatsApp for Technical support or query, Service centre
                    location, Repair status, Demo &amp; Installation request<br /> WhatsApp Us: <a href={process.env.REACT_APP_WHATSAPP_MOB} target='_blank'>1-800-5-726-7864 </a><br />Available 24 Hours / 7 days</span>
                  <div className="su11-link-card__list-item-cta">
                    <span className="su11-link-card__list-item-cta-wrap">
                      <a className="cta cta--contained cta--black" target='_blank' href={process.env.REACT_APP_WHATSAPP_MOB} onClick={e => props.handleLogging('', { ChildMenuRedirectURL: process.env.REACT_APP_WHATSAPP_MOB, ChildMenuName: 'WhatsApp Mob' }, 1, 'Contact Info')}>
                        WhatsApp
                      </a>
                    </span>
                  </div>
                </div>
              </div>

              <div className="su11-link-card__list-item card-visible">
                <div className="su11-link-card__list-item-wrap">
                  <h3 className="su11-link-card__list-item-title">
                    Sign Language
                    <div className="su11-link-card__list-item-icon">
                      <div className="image image--main-loaded">
                        <img className="image__main responsive-img image--loaded" src="/img/contact/sign-language7.png" alt='sign-language' />
                      </div>
                    </div>
                  </h3>
                  <span className="su11-link-card__list-item-description">Video call with Sign Language interpreter assisted by
                    Samsung product expert.<br /><br />Operation Hour: Monday - Friday 11:00-16:00</span>
                  <div className="su11-link-card__list-item-cta">
                    <span className="su11-link-card__list-item-cta-wrap">
                      <a className="cta cta--contained cta--black cta--icon" href="https://wa.me/91180057267864?text=hi">
                        Video chat with us
                        <img className="icon" src="/img/svg-icons/outlink-bold.svg" alt='outlink-bold' />
                      </a>
                    </span>
                  </div>
                </div>
              </div>

              <div className="su11-link-card__list-item card-visible">
                <div className="su11-link-card__list-item-wrap">
                  <h3 className="su11-link-card__list-item-title">
                    Email Support
                    <div className="su11-link-card__list-item-icon">
                      <div className="image image--main-loaded">
                        <img className="image__main responsive-img image--loaded" src="/img/contact/email.png" alt='email' />
                      </div>
                    </div>
                  </h3>
                  <span className="su11-link-card__list-item-description">We'll respond within 24 hours of your request.
                    <br /><br />

                    {/* <strong>Srilanka : support.lk@samsung.com</strong> */}
                  </span>
                  <div className="su11-link-card__list-item-cta">
                    <span className="su11-link-card__list-item-cta-wrap">
                      <a className="cta cta--contained cta--black " href={process.env.REACT_APP_EMAIIL_SUPPORT_INDIA} target="_self" onClick={e => { props.handleLogging('', { ChildMenuRedirectURL: process.env.REACT_APP_EMAIIL_SUPPORT_INDIA, ChildMenuName: 'Email Support India' }, 1, 'Contact Info'); handlecheckLogin(e) }}>
                        Email Us
                      </a>
                      <a ref={hiddenAnchorRef} style={{ visibility: 'hidden' }} href={`mailto:support.india@samsung.com?subject=Query &body=Dear Customer,%0D%0A%0D%0A Please provide following details along with your query.%0D%0A%0D%0A 
					              %20%20%20%20%20%20%20%20First Name : ${userDetails.given_name || ''}  %0D%0A
                        Last Name :  ${userDetails.family_name || ''} %0D%0A
                        Contact Number : ${userDetails.mobileNumber || ""} %0D%0A
                        Customer ID : ${partnerCode || ''} %0D%0A
                        Product Model Number :  %0D%0A
                        Product Serial number :  %0D%0A
                        Address :  %0D%0A
                        Please describe your query : `}></a>
                    </span>
                    {/* <span className="su11-link-card__list-item-cta-wrap">
                <a className="cta cta--contained cta--black " href={process.env.REACT_APP_EMAIIL_SUPPORT_BANGLAGESH}  target="_self"onClick={e=>props.handleLogging('',{ChildMenuRedirectURL:process.env.REACT_APP_EMAIIL_SUPPORT_BANGLAGESH,ChildMenuName:'Email Support Bangladesh'},1,'Contact Info')}>
                Bangladesh
                </a>
              </span> */}
                    {/* <span className="su11-link-card__list-item-cta-wrap">
                <a className="cta cta--contained cta--black "  href="mailto:support.lk@samsung.com" onClick={e=>props.handleLogging('',{ChildMenuRedirectURL:"mailto:support.lk@samsung.com",ChildMenuName:'Email Support SriLanka'},1,'Contact Info')}>
                Sri Lanka
                </a>
              </span> */}
                  </div>
                </div>
              </div>

              <div className="su11-link-card__list-item card-visible">
                <div className="su11-link-card__list-item-wrap">
                  <h3 className="su11-link-card__list-item-title">
                  Phone Support (India)
                    <div className="su11-link-card__list-item-icon">
                      <div className="image image--main-loaded">
                        <img className="image__main responsive-img image--loaded" src="/img/contact/call.png" />
                      </div>
                    </div>
                  </h3>
                  <span className="su11-link-card__list-item-description"><b>1-800-5-726-7864</b>
               <br /><b>1-800-40-726-7864</b><br/>
               <b>Available: 8 AM to 8 PM (All 7 days)</b></span>
                  <div className="su11-link-card__list-item-cta">
                    <span className="su11-link-card__list-item-cta-wrap">

                      <a className="cta cta--contained cta--black " href="tel:180057267864" target="_self">
                        ☏ : Tollfree Helpline 1
                      </a>
                    </span>

                    <span className="su11-link-card__list-item-cta-wrap">

                      <a className="cta cta--contained cta--black " href="tel:1800407267864" target="_self">
                        ☏ : Tollfree Helpline 2
                      </a>
                    </span>
                  </div>
                </div>
              </div>

              {/* <div className="su11-link-card__list-item card-visible">
          <div className="su11-link-card__list-item-wrap">
            <h3 className="su11-link-card__list-item-title">
              Phone Support
              <div className="su11-link-card__list-item-icon">
                <div className="image image--main-loaded">
                  <img className="image__main responsive-img image--loaded" src="/img/contact/call.png" alt="call"/>
                </div>
              </div>
            </h3>
            <span className="su11-link-card__list-item-description">
              (India, Bangladesh, Sri Lanka, Nepal)
            </span>
            <div className="su11-link-card__list-item-cta">
              <span className="su11-link-card__list-item-cta-wrap">
                <a className="cta cta--contained cta--black cta--icon" href={process.env.REACT_APP_PHONE_SUPPORT} onClick={e=>props.handleLogging('',{ChildMenuRedirectURL:process.env.REACT_APP_PHONE_SUPPORT, ChildMenuName :'Phone Support'},1,'Contact Info')}>
               India, Bangladesh, Sri Lanka, Nepal Phone Support
                </a>
              </span>
            </div>
          </div>
        </div> */}

              <div className="su11-link-card__list-item card-visible">
                <div className="su11-link-card__list-item-wrap">
                  <h3 className="su11-link-card__list-item-title">
                    Apps &amp; Services Customer Support
                  </h3>
                  <span className="su11-link-card__list-item-description">Samsung Pay : 1800-123-7729<br />Samsung Free : 1800 40 SAMSUNG (7267864) </span>
                  <div className="su11-link-card__list-item-cta">
                  </div>
                </div>
              </div>

              <div className="su11-link-card__list-item card-visible">
                <div className="su11-link-card__list-item-wrap">
                  <h3 className="su11-link-card__list-item-title">
                    CEO Office
                    <div className="su11-link-card__list-item-icon">
                      <div className="image image--main-loaded">
                        <img className="image__main responsive-img image--loaded" src="/img/contact/ceo-office.png" alt="ceo-office" />
                      </div>
                    </div>
                  </h3>
                  <span className="su11-link-card__list-item-description">Share feedback to CEO office & relevant team will get in touch with you promptly</span>
                  <div className="su11-link-card__list-item-cta">
                    <span className="su11-link-card__list-item-cta-wrap">
                      <a className="cta cta--contained cta--black " href={process.env.REACT_APP_CEO_OFFICE} target="_self" onClick={e => props.handleLogging('', { ChildMenuRedirectURL: process.env.REACT_APP_CEO_OFFICE, ChildMenuName: 'CEO Office' }, 1, 'Contact Info')}>
                        Email Us
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <SignInModal  {...props} routData={routData} popupId={'signInPopUpEmailUs'} /> */}
    </React.Fragment>
  )
}

export default MobContactUs