import React, {useEffect} from 'react'
import { useDispatch } from 'react-redux';
import actions from "../../../Redux/Action";
import { TailSpin } from "react-loader-spinner";

const Landing = () => {
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(actions.CommonActions.showLoader(true))
    dispatch(actions.CommonActions.signIn(true))
  },[])
  return (
    <div className="loaderBox">
      <TailSpin color="#ffffff"  height={80}  width={80}  className={"loder"}/>
    </div>
  )
}

export default Landing