import React from "react";

const ExploreMorePopUp = (props) => {
  return (
    <>
      <div className="modal-explore hidden">
        <button className="btn--close-modal btn--closeExplore">&times;</button>

        <footer className="footer footer-explore-more">
          <div className="footer-column">
            {props &&
              props.exploreservice &&
              props.exploreservice.ChildMenus &&
              props.exploreservice.ChildMenus.length > 0 &&
              props.exploreservice.ChildMenus.map((item,index) => {
                return (
                  <div
                    className="footer-column__item"
                    key={item.ChildMenuID || ""}
                  >
                    <div className="footer-category">
                      <h3 className="footer-category__title">
                        <span className="circle-out">
                          <img
                            src={item.ChildMenuIcon || ""}
                            className="icon"
                            alt={item.ChildMenuTitle}
                          />
                        </span>

                        {item.ChildMenuTitle || ""}
                      </h3>

                      <a
                        className="footer-category__anchor"
                        target="_self"
                        href={item.ChildMenuRedirectURL || ""}
                        onClick={e=>props.handleLogging('',item,index+1,'ExploreMore')}
                      >
                        <img
                          className="icon"
                          src="img/svg-icons/bottom-arrow.svg"
                          alt="bottom-arrow"
                        />
                      </a>
                    </div>
                  </div>
                );
              })}
          </div>
        </footer>
      </div>
    </>
  );
};

export default ExploreMorePopUp;
