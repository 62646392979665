import React, { useEffect } from 'react';

const ShopAppUtil = {
  setUserDetails: (data) => {
    console.log(data);
  },
  callHandler: (methodName, params) => {
    if (window.flutter_inappwebview) {
      return window.flutter_inappwebview.callHandler(methodName, params);
    } else {
      return Promise.reject(`Calling methodName: ${methodName}, but webview not identified`);
    }
  },
  logger: (info, value) => {
    console.log(`${info} ${value}`);
  },
  getUserDetails: () => {
    return new Promise((resolve, reject) => {
      ShopAppUtil.callHandler('getUserDetails', [ShopAppUtil.setUserDetails])
        .then(result => {
          ShopAppUtil.logger('*User Details', result);
          resolve(result);
        })
        .catch(err => {
          ShopAppUtil.logger('Error in getUserDetails', err);
          reject(err);
        });
    });
  }
};

const MyComponent = () => {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfo = await ShopAppUtil.getUserDetails();
        console.log(JSON.stringify(userInfo));
      } catch (error) {
        console.log(JSON.stringify(error));
      }
    };

    fetchData();
  }, []);

  return (<>
    <div>eshop comp</div>
    </>)
};

export default MyComponent;
