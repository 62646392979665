import React, { Suspense, lazy } from "react";

import {Loading} from "@Core/Components/Loader";

const LoginWrapper = lazy(() =>
  import(/* webpackChunkName: "login" */ "./view")
);

const SelfhelpWrapper = (props) => {
  return (
    <>
      <Suspense fallback={<Loading />}>
        <LoginWrapper {...props} />
      </Suspense>
    </>
  );
};

export default SelfhelpWrapper;