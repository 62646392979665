import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from 'Redux/store';
import { ToastContainer } from 'react-toastify';
import AddToHomeScreen from '@ideasio/add-to-homescreen-react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-toastify/dist/ReactToastify.css'
import 'react-magic-slider-dots/dist/magic-dots.css';
import "react-datepicker/dist/react-datepicker.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Provider store={store}>
    <App />
    <ToastContainer position="top-right" autoClose={2000} hideProgressBar={false}newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover/>
    <AddToHomeScreen title={"Install DSC Web APP ?"} src={"/favicon.png"} installMsg={"Installed Successfully"} startDelay={20} lifeSpane={60} startAutomatically={true}/>
    </Provider>
  </>
);

reportWebVitals();
