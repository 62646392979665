import React from "react";
import Slider from "react-slick";
import MagicSliderDots from 'react-magic-slider-dots';
import CommonFunction from "@Core/Components/CommonFunction";
import { PopUp } from "./../../@Core/Library";
const MyProductsComp = (props) => {

  return (
    <>
      {props.isMobile ? (
        <MyProductMob {...props} />
      ) : (
        <MyProductDesk {...props} />
      )}
    </>
  );
};

const MyProductMob = (props) => {
  let productList =
    props.userData &&
      Object.keys(props.userData).length && props.userData.productList &&
      props.userData.productList.length
      ? props.userData.productList
      : [];
  let userData = props.userData && Object.keys(props.userData).length ? props.userData : {}
  const settings = {
    dots: props.isMobile ? false : true,
    arrows: props.isMobile ? false : true,
    infinite: false,
    speed: 500,
    variableWidth: true,
    adaptiveHeight: false,
    slidesToShow: 6,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        }
      },
    ],
    appendDots: dots => {
      return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={20} />;
    }
  };
  return (
    <>
      <div className="su-g-search-product-card aem-GridColumn aem-GridColumn--default--12 show-mobile mobile-product-slider">
        <div className="search-product-card">
          <div className="search-product-card__support">
            <div className="add-btn-cover">
              <h2 className="search-product-card__title" data-font-size-mo="34">
                My Products{" "}
                {productList.length > 0 ? "(" + productList.length + ")" : ""}
              </h2>
              {productList.length > 0 && <div className="mb-product-add-btn">
                <a className="cta cta--outlined cta--black cta--icon" href=''
                  //onClick={(e) => { e.preventDefault(); PopUp(true, 'addNewProdModal'); }}
                  onClick={e => { CommonFunction.handleRedirect(e, `${process.env.REACT_APP_LINK_BASE_URL}support/your-service/register-product`, { svcFrm: 'dsc', siteCode: 'in' }); props.handleLogging('', { ChildMenuRedirectURL: 'https://www.samsung.com/in/support/your-service/register-product?svcFrm=dsc&siteCode=in' }, 1, 'AddNewProduct'); PopUp(true, 'addPoductMsgModal'); }}
                >
                  Add
                </a>
              </div>}
            </div>
            <div className="pd-g-feature-benefit-column-carousel">
              <div className="feature-column-carousel   bg-white feature-column-carousel--width-1206-edge  feature-column-carousel--desktop-columns-space-wide  ">
                <div className="feature-column-carousel__column  feature-column-carousel__column--align-center">
                  <Slider
                    className="feature-column-carousel__inner custom-product-slider mobile-scroll"
                    {...settings}
                  >
                    {productList && productList.length > 0 ? (
                      productList.map((item, index) => {
                        return (
                          <div
                            className={`feature-column-carousel__item feature-column-carousel__item--align-center ${item.IsOfferAvailable && props.homeView ? 'overflow-visible' : ''}`}
                            key={index}
                          >
                            {props.homeView &&
                              <div className="mb-product-edit-btn">
                                <a
                                  className="cta cta--contained cta--black"
                                  href=""
                                  //onClick={(e) => { e.preventDefault(); PopUp(true, 'modifyProdModal'); }}
                                  onClick={e => { CommonFunction.handleRedirect(e, `${process.env.REACT_APP_LINK_BASE_URL}support/your-service/my-product`, { svcFrm: 'dsc', prcSeq: item.PRC_SEQ, partner: userData.partnerCode, cicPrd: item.CIC_PRD }); props.handleLogging(e, { ChildMenuRedirectURL: `${process.env.REACT_APP_LINK_BASE_URL}support/your-service/my-product?svcFrm=dsc&prcSeq=${item.PRC_SEQ}&partner=${userData.partnerCode}&cicPrd=${item.CIC_PRD}` }, index + 1, 'Modify') }}
                                >
                                  {" "}
                                  <img
                                    className="icon"
                                    src="/img/svg-icons/edit-bold.svg"
                                    alt="edit"
                                  />
                                </a>
                              </div>}

                            <div className="feature-column-carousel__feature bg-light-gray">
                              {/* Mobile Mode starts*/}
                              {item.IsOfferAvailable && props.homeView &&
                                <div className="feature-column-carousel__figure generatecode-bx-wrapper">
                                  <div className="generatecode-bx" onClick={e => { props.handleVoucherModal(e, 'voucherModal', item); props.handleLogging(e, { ChildMenuRedirectURL: '/', ChildMenuName: "Generate Coupon Code" }, 1, 'Coupon Code') }}>
                                    <img src="img/srpopup/discounted.png" />
                                    <p className="show-big-screen">One Time Screen Guard Replacement</p>
                                    <p>Generate Code <span>For 1 time Screen Guard</span></p>
                                    {/* <div><a className="next cta cta--contained cta--icon" href="" onClick={e =>{props.handleVoucherModal(e,'voucherModal',item)}}>Generate Code</a></div> */}
                                  </div>

                                </div>
                                //    <div className="feature-column-carousel__figure generatecode-bx-wrapper">
                                //    <div className="generatecode-bx">
                                //    <a className="next cta cta--contained cta--icon mr-2" href="" onClick={e =>{props.handleVoucherModal(e,'voucherModal',item)}}> <img src="img/srpopup/discounted.png"  />
                                //     Offer</a>
                                //    </div>
                                //  </div>
                              }
                              {/* Mobile Mode ends*/}
                              <div className="feature-column-carousel__figure">
                                <div className="image image--main-loaded">
                                  {" "}
                                  <img
                                    className="image__main responsive-img image--loaded"
                                    src={item.Image_URL}
                                    alt={item.MODEL_NAME}
                                  />
                                </div>
                              </div>

                              <div className="feature-column-carousel__content">
                                <div className="feature-column-carousel__text-wrap">
                                  <div className="feature-column-carousel__sub-title">
                                    <h4 tabIndex="0" data-font-size-pc="24" data-font-size-mo="34" className="nodata-height-fix" data-tooltip="I am so glad you decided to hover!" >
                                      {item.MODEL_NAME}
                                    </h4>
                                    <p className="product-details-desc" data-font-size-mo="28" >
                                      Serial : {item.SERIAL_NO}
                                    </p>
                                    <p className="product-details-desc" data-font-size-mo="28"  >
                                      {/* Purchase Date : {item.PURCHASE_DATE} */}
                                    </p>
                                  </div>
                                </div>
                                {props.SourceView == 'trackDetails' ?
                                  <div className="feature-column-carousel__button">
                                    <a className="cta cta--contained cta--black" href="" onClick={(e) => props.handleProductTrackDetails(e, item)} >
                                      {props.buttonName}
                                    </a>
                                  </div> :
                                  props.SourceView == 'spareRepair' ? <div className="feature-column-carousel__button">
                                    <a className="cta cta--contained cta--black" href="" onClick={(e) => { props.handleProductSparePartDetails(e, item); props.handleLogging('', { ...item, ChildMenuRedirectURL: '/spareparts' }, index + 1, 'Show SparePart Details') }} >
                                      View Cost
                                    </a>
                                  </div> : <div className="feature-column-carousel__button">
                                    <a className="cta cta--contained cta--black" href="" onClick={(e) => props.handleProductDetails(e, item)} >
                                      Support
                                    </a>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          {!productList.length && <div className="no-product-found">
            <img src="/img/no-result-found.png" className="no-result-img" alt="no-result" />
            <span className="no-product-txt">No Product Registered </span>
            <a className="cta cta--outlined cta--black cta--icon" href=''
              onClick={e => { CommonFunction.handleRedirect(e, `${process.env.REACT_APP_LINK_BASE_URL}support/your-service/register-product`, { svcFrm: 'dsc', siteCode: 'in' }); props.handleLogging('', { ChildMenuRedirectURL: 'https://www.samsung.com/in/support/your-service/register-product?svcFrm=dsc&siteCode=in' }, 1, 'AddNewProduct'); PopUp(true, 'addPoductMsgModal'); }}   >
              Add New Product
            </a>

            {/* <a className="cta cta--outlined cta--black cta--icon" href='' 
                onClick={(e) => { e.preventDefault(); PopUp(true, 'addNewProdModal'); }}>
                  Add New Product
            </a> */}
          </div>}
        </div>
      </div>
    </>
  );
};
const MyProductDesk = (props) => {
  const settings = {
    dots: props.isMobile ? false : true,
    arrows: props.isMobile ? false : true,
    infinite: false,
    speed: 500,
    variableWidth: true,
    adaptiveHeight: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
    ],
    appendDots: dots => {
      return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={20} />;
    }
  };
  let productList = props &&
    props.userData &&
    Object.keys(props.userData).length && props.userData.productList &&
    props.userData.productList.length
    ? props.userData.productList
    : [];
  let userData = props.userData && Object.keys(props.userData).length ? props.userData : {}
  return (
    <>
      <div className="su-g-search-product-card aem-GridColumn aem-GridColumn--default--12 show-desktop">
        <div className="search-product-card">

          <div className="search-product-card__support">
            <h2 className="search-product-card__title" data-font-size-mo="34">
              My Products{" "}
              {productList.length > 0 ? "(" + productList.length + ")" : ""}
            </h2>
            {productList.length > 0 && <><div className="pd-g-feature-benefit-column-carousel">
              <div className="feature-column-carousel   ">
                <div className="feature-column-carousel__column  feature-column-carousel__column--align-center">
                  <Slider
                    className="feature-column-carousel__inner custom-product-slider"
                    // slidesToShow={3}
                    // slidesToScroll={2}
                    {...settings}
                  >
                    {productList && productList.length > 0 ? (
                      productList.map((item, index) => {
                        return (
                          <div
                            className="feature-column-carousel__item feature-column-carousel__item--align-center"
                            key={index}
                          >
                            <div className="feature-column-carousel__feature bg-light-gray">
                              {item.IsOfferAvailable && props.homeView &&
                                <div className="feature-column-carousel__figure generatecode-bx-wrapper">
                                  <div className="generatecode-bx" onClick={e => { props.handleVoucherModal(e, 'voucherModal', item); props.handleLogging(e, { ChildMenuRedirectURL: '/', ChildMenuName: "Generate Coupon Code" }, 1, 'Coupon Code') }}>
                                    <img src="img/srpopup/discounted.png" />
                                    <p>One Time Screen Guard Replacement</p>
                                    <div><a className="next cta cta--contained cta--icon offerIcon" href="">Generate Code</a></div>
                                  </div>

                                </div>
                              }
                              <div className="feature-column-carousel__figure">
                                <div className="image image--main-loaded">
                                  <img
                                    className={`image__main responsive-img image--loaded ${item.IsOfferAvailable && props.homeView ? 'offer-available-img' : ''}`}
                                    src={item.Image_URL || ""}
                                    alt={item.MODEL_NAME}
                                  />
                                </div>
                              </div>
                              <div className="feature-column-carousel__content">
                                <div className="feature-column-carousel__text-wrap">
                                  <div className="feature-column-carousel__sub-title">
                                    <h4
                                      tabIndex="0"
                                      data-font-size-pc="24"
                                      data-font-size-mo="34"
                                      className="nodata-height-fix"
                                    >
                                      {item.MODEL_NAME}
                                    </h4>

                                    <p
                                      className="product-details-desc"
                                      data-font-size-mo="28"
                                    >
                                      Serial : {item.SERIAL_NO || ""}
                                    </p>

                                    <p
                                      className="product-details-desc"
                                      data-font-size-mo="28"
                                    >
                                      {/* Purchase Date : {item.PURCHASE_DATE || ""} */}
                                    </p>
                                  </div>
                                </div>
                                {props.SourceView == 'home' ?
                                  <div className="feature-column-carousel__button">
                                    <a
                                      className="cta cta--contained cta--black"
                                      href=""
                                      onClick={(e) => { props.handleProductDetails(e, item); props.handleLogging('', item, index + 1, 'Product') }
                                      }
                                    >
                                      Support
                                    </a>

                                    <a
                                      className="cta cta--outlined cta--black"
                                      href=""
                                      //onClick={(e) => { e.preventDefault(); PopUp(true, 'modifyProdModal'); }}
                                      onClick={e => { CommonFunction.handleRedirect(e, `${process.env.REACT_APP_LINK_BASE_URL}support/your-service/my-product`, { svcFrm: 'dsc', prcSeq: item.PRC_SEQ, partner: userData.partnerCode, cicPrd: item.CIC_PRD }); props.handleLogging(e, { ChildMenuRedirectURL: `${process.env.REACT_APP_LINK_BASE_URL}support/your-service/my-product?svcFrm=dsc&prcSeq=${item.PRC_SEQ}&partner=${userData.partnerCode}&cicPrd=${item.CIC_PRD}` }, index + 1, 'Modify') }}
                                    >
                                      Modify
                                    </a>
                                  </div> : props.SourceView == 'spareRepair' ?
                                    <div className="feature-column-carousel__button">
                                      <a
                                        className="cta cta--contained cta--black"
                                        href=""
                                        onClick={(e) => { props.handleProductSparePartDetails(e, item); props.handleLogging('', { ...item, ChildMenuRedirectURL: '/spareparts' }, index + 1, 'Show SparePart Details') }
                                        }
                                      >
                                        View Cost
                                      </a>
                                    </div> : props.SourceView == 'trackDetails' ? <div className="feature-column-carousel__button">
                                      <a
                                        className="cta cta--contained cta--black"
                                        href=""
                                        onClick={(e) => { props.handleProductTrackDetails(e, item); props.handleLogging('', { ...item, ChildMenuRedirectURL: '/trackdetails' }, index + 1, 'Show Track Details') }
                                        }
                                      >
                                        {props.buttonName}
                                      </a>
                                    </div> : ''
                                }
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </Slider>
                </div>
              </div>
            </div>
              <div className="su11-link-card__more-cta">
                <a className="cta cta--outlined cta--black cta--icon" href='' target='_blank'
                  onClick={e => { CommonFunction.handleRedirect(e, `${process.env.REACT_APP_LINK_BASE_URL}support/your-service/register-product`, { svcFrm: 'dsc', siteCode: 'in' }); props.handleLogging('', { ChildMenuRedirectURL: 'https://www.samsung.com/in/support/your-service/register-product?svcFrm=dsc&siteCode=in' }, 1, 'AddNewProduct'); PopUp(true, 'addPoductMsgModal'); }}   >
                  Add New Product
                </a>

                {/* <a className="cta cta--outlined cta--black cta--icon" href='' 
                onClick={(e) => { e.preventDefault(); PopUp(true, 'addNewProdModal'); }}>
                  Add New Product
                </a> */}

              </div></>}
          </div>
          {!productList.length && <div className="no-product-found">
            <img src="/img/no-result-found.png" className="no-result-img" alt="no-result" />
            <span className="no-product-txt">No Product Registered </span>
            <a className="cta cta--outlined cta--black cta--icon" href='' onClick={e => { CommonFunction.handleRedirect(e, `${process.env.REACT_APP_LINK_BASE_URL}support/your-service/register-product`, { svcFrm: 'dsc', siteCode: 'in' }); props.handleLogging('', { ChildMenuRedirectURL: 'https://www.samsung.com/in/support/your-service/register-product?svcFrm=dsc&siteCode=in' }, 1, 'AddNewProduct'); PopUp(true, 'addPoductMsgModal') }}  >
              Add New Product
            </a>
            {/* <a className="cta cta--outlined cta--black cta--icon" href='' 
                onClick={(e) => { e.preventDefault(); PopUp(true, 'addNewProdModal'); }}>
                  Add New Product
            </a> */}
          </div>}
        </div>
      </div>
    </>
  );
};
export default MyProductsComp;
