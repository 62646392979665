import axios from "axios";
import { cookie } from 'react-cookie';
import apipaths from "@Core/Apis/apiPaths";

// export default axios.create({
//         baseURL : 'http://localhost:3000/'
// });

const self = {
        method: "GET",
	headers: {
		'Content-Type' : 'application/json'
	},
	fingerPrint : '',
	setMethod: function(method) {
		self.method = method;
		return self;
	},
	setHeader: function(key, value) {
		self.headers[key] = value;
		return self;
	},
	reset: function() {
        self.method = "GET";
        self.headers = {"Content-Type" : "application/json"};
        return self;
        },
	setFingerPrint : function(fingerPrint){
		self.fingerPrint = fingerPrint;
		return self;
	},
        sendRequest: function(url, data, authenticate, callback, dispatch, responseType = 'json') {
                // if (self.fingerPrint) {
		// 	self.setHeader('fingerPrint', self.fingerPrint)
		// } else {
		// 	var fingerPrintFromCookie = (typeof cookie.load('fingerPrint') != 'undefined') ? cookie.load('fingerPrint') : ''
		// 	self.setHeader('fingerPrint', fingerPrintFromCookie);
		// 	self.setFingerPrint(fingerPrintFromCookie);
		// }
		// // eslint-disable-next-line no-unused-expressions
		// (authenticate) ? self.setHeader('token', (typeof cookie.load('token') != 'undefined') ? cookie.load('token') : ''):'';
		return axios({
			method: self.method,
                        crossOriginIsolated :true,
			url: apipaths.BASE_URL + url,
			responseType,
			headers: self.headers,
			data: data,
			timeout : 240000,
			params: (self.method == "GET") ? data : {}
		})
		.then(function (response) {
				self.reset();
				if(responseType == 'blob' && response.status !== 200) {
					throw new Error('Download Error')
				}
				//this if condition has been added to logout on behalf account if the connection does not exit at the time of api hit
				else if (authenticate) {
					if (response.data.code === "CEC4007") {

						dispatch({
							type:'connectionRemoved',
							data:true
						});
					}
					// commented for guest user
					// if (["CEC4003"].indexOf(response.data.code) != -1) {
					// 	if (self.handleApiError()) {
					// 		dispatch({
					// 			type: 'logout',
					// 			error: false,
					// 			msg: "Request not fulfilled " + response.data.code,
					// 			isAuthenticated: auth.isAuthenticated()
					// 		});
					// 	}
					// }
				}

			callback(response);
		})
		.catch(function (error) {
			//if (self.handleApiError()) {
				// dispatch(common.removeNotification());
				// dispatch(common.notify(error.message == "Download Error" ? "No files available for download." : "Something went wrong. Please try again later.", 'error'));
			//}
			callback(error.response);
			console.error("API LIB ERROR : ", error);
		});
	}
}
    
export default self;