import React,{useEffect} from "react";
import { useDispatch  } from 'react-redux';
import actions from "../../../Redux/Action";
import CommonFunction from "@Core/Components/CommonFunction";
const ErrorView = () => {
  const dispatch = useDispatch()
  const browserName = CommonFunction.getBrowser()
  
  let data=  [
    {
        "EventName":'Error',
        "BrowserName":browserName,
        "LinkName":'somting went wrong',
        "SourceType":Boolean(window.innerWidth < 767) ? 1 : 2,
    }
  ]
  useEffect(()=>{
    dispatch(actions.CommonActions.insertLoggingApi(data))
  },[])
  return (
    <div>
      somting went wrong
    </div>
  );
};

export default ErrorView;