import React from "react";
import { GnbFooter } from "./GnbFooter";

const Footer = (props) => {
  return (
    <>
      <GnbFooter {...props}/>
      {props.isMobile && <MobFooter {...props}/> }
    </>
  );
};

const MobFooter = (props) => {
  return (
    <div className="nav__menu  show-mobile">
    <ul className="nav__list">
      <li className="nav__item">
        <a href="" className={`nav__link ${props.navMenu == 'home' ? 'active-link' : ''}`} onClick={e=>{props.handleNavManu(e,'home');props.handlePageRoute('home')}}>
          <img src="/img/menu/home.svg" className="foo-img" alt="home"/>
          <span className="nav__name">Home</span>
        </a>
      </li>

      <li className="nav__item">
        <a href="https://www.samsung.com/in/support/service-center/" className="nav__link">
          <img src="/img/menu/service.svg" className="foo-img"  alt="service"/>
          <span className="nav__name">Service Centers</span>
        </a>
      </li>

      <li className="nav__item">
        <a href="" className={`nav__link ${props.navMenu== 'quickTips' ? 'active-link' : ''}`} onClick={e=>props.handleNavManu(e,'quickTips')}>
          <img src="/img/menu/quick-tips.svg" className="foo-img"  alt="quick-tips"/>
          <span className="nav__name">Quick Tips</span>
        </a>
      </li>

      <li className="nav__item contactguid">
        <a href="" className={`nav__link ${props.navMenu== 'contactUs' ? 'active-link' : ''}`} onClick={e=>props.handleNavManu(e,'contactUs')}>
          <img src="/img/menu/contact.svg" className="foo-img"  alt="contact"/>
          <span className="nav__name">Contact</span>
        </a>
      </li>
    </ul>
  </div>
  )
}
export default Footer;
