const InitialData = {
    error: false,
    msg: "",
    BookAppointmentData: { BAStep: 1, TAStep: 1, Tab: "newBook", error: {} },
    PickDropData: { PDStep: 1, Tab: "newBook",selectedAddressData:{}, error: {} },
    location: { loaded: false, coordinates: { lat: "", lng: "" } },
    SpareRepaireData: {},
    DiyData: { search: "", searchBy: 2, filterType: "All", filterOpp: [] ,isGlobalSearch: false},
    TrackDetailsData: {error:{},SlectedRequest:{showfeedback:false}},
};
export default InitialData;
