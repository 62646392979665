import { combineReducers } from "redux";
import productReducer from './productReducer'
import ServiceReducer from './ServiceReducer'

export const reducers = combineReducers({
    productReducer : productReducer,
    ServiceReducer : ServiceReducer,
});

export const initialState = {
    productReducer    	:  productReducer({}, {type: "init"}),
    ServiceReducer    	:  ServiceReducer({BookAppointmentData:{BAStep:1}}, {type: "init"}),
}