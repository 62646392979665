import React from 'react'
import { PopUp } from '@Core/Library';

const MailQuestionFormModal = (props) => {
    return (
        <>
            <div className="deepLinking">
                <div className="modal-content hidden" id='QuestionFormModalID'>
                    <div className="modal-header">
                        {/* <h2
                        className="form-title my-2 ml-4 mt-4"
                        data-font-size-mo="16"
                        data-font-size-pc="20"
                    >
                        Schedule Call Support
                    </h2> */}
                    </div>
                    <div className="modal-body">
                        <div className="row center-xs bottom-xs ">
                            <div className="col-md-12 col-xs-12">
                                <div className="confirmation-req">
                                    <div className="row center-xs bottom-xs ">
                                        <div className="col-md-12 col-xs-12 p-4">
                                            <img src="../img/svg-icons/success.svg" className="success-icon my-4" alt="success" />
                                            <p data-font-size-mo="12" data-font-size-pc="16" className="mb-2 px-2" >
                                                Thank you for your contact!
                                            </p>
                                            <p data-font-size-mo="12" data-font-size-pc="16" className="mb-2 px-2" >
                                                Request # {props.RefNum || ''} has been submitted successfully.
                                            </p>
                                            <p data-font-size-mo="12" data-font-size-pc="16" className="mb-2 px-2" >
                                                We will revert to you within 24hrs.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <a className="cta cta--contained cta--black cta--icons px-5" href="" onClick={e => { PopUp(false, 'QuestionFormModalID'); e.preventDefault(); props.setRefNum('') }}>OK</a>
                    </div>
                </div>
            </div>
            <div className="overlay hidden registration" id='overLay'></div>
        </>
    )
}

export default MailQuestionFormModal
