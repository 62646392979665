export const set = (key, value) => {
  try {
    return window?.localStorage?.setItem(key, btoa(JSON.stringify(value)));
  } catch (error) {
    //console.log(`Failed to write 'localStorage' data:  ${error}`);
    return null;
  }
};
export const get = (key = "") => {
  try {
    return JSON.parse(atob(window?.localStorage?.getItem(key))) || null;
  } catch (error) {
    //console.log(`Failed to convert 'localStorage' data:  ${error}`);
    return null;
  }
};
export const remove = (key = "") => {
  return localStorage.removeItem(key) || null;
};
