import React from 'react'
import { TailSpin } from "react-loader-spinner";
const Loader = (props) => {
  return (
    props.showLoader && <div className="loaderBox">
    {props.loaderData && Object.keys(props.loaderData).length>0 ?
    <div className="loader-welcome-txt">
      <h2 className="loader-head search-product-card__title text-color--white" data-font-size-mo="34">{`Welcome ${props.loaderData.given_name||''} ${props.loaderData.family_name||''}`}</h2>
      <span className="loader-bdy text-color--white">We're Personalizing Your Experience....</span>
    </div>:''}
    <TailSpin color="#ffffff"  height={80}  width={80}  className={"loder"}/>
  </div>
  )
}

export default Loader

export const Loading = ({ children }) => {
    return (
        <TailSpin color="#ffffff"  height={80}  width={80}  className={"loder"}>{children}</TailSpin>
    );
  };