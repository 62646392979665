import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import actions from "../../../Redux/Action";
import CommonFunction from '@Core/Components/CommonFunction';
import { toast } from 'react-toastify';
import MultipleInputFile from '@Core/Components/MultipleInputFile';
import Helmet from "@Core/Components/Helmet";
import Loader from '@Core/Components/Loader';
import MailQuestionFormModal from './MailQuestionFormModal';
import { PopUp } from '@Core/Library';
const random = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};
const MailQuestionGeneralNps = (props) => {
  let initialState = {
    FirstName: "",
    LastName: "",
    Email: "",
    PhoneNo: "",
    Address: "",
    Subject: "",
    Message: "",
    Captcha: "",
  }
  let showLoader = useSelector((state) => state.productReducer.showLoader) || false
  let loaderData = useSelector((state) => state.productReducer.loaderData) || {}
  let initialButton = [{ key: random(), name: 'No file selected', size: null }]
  useEffect(() => {
    GetCaptchaDetails();
  }, [])

  const dispatch = useDispatch()
  const [state, setState] = useState(initialState)
  const [error, setError] = useState({})
  const [RefNum, setRefNum] = useState('')
  const [capthadata, setCapthaData] = useState({})
  const [files, setFiles] = useState(initialButton);
  let MetaTag = useSelector((state) => state.productReducer.GetMetaTag) || []
  let metaData = MetaTag && MetaTag.length > 0 && MetaTag.filter(item => item.PageName == process.env.REACT_APP_MATATAG_PAGENAME_MAILQESTIONGENERAL_NPS).length ? MetaTag.filter(item => item.PageName == process.env.REACT_APP_MATATAG_PAGENAME_MAILQESTIONGENERAL_NPS) : []
  const GetCaptchaDetails = () => {
    dispatch(actions.CommonActions.showLoader(true))
    let data = {
      //Token: userTokenData.UserToken
    }
    dispatch(actions.ServiceAction.GetCaptcha(data, (res) => {
      if (res.IsSuccess) {
        setCapthaData(res.Entity)
      } else {
        toast.error(res.Message)
      }
      dispatch(actions.CommonActions.showLoader(false))
    }))
  }

  const handleInput = (e) => {
    let { name, value } = e.target;
    delete error[name];
    if (name == 'PhoneNo') {
      value = String(value).replace(/[^\d-]/g, "");
      if (value.length > 10) return;
    } else if (name == 'FirstName' || name == 'LastName') {
      value = String(value).replace(/[^\w\s\][,]/gi, '')
    } else if (name == 'Address') {
      value = String(value).replace(/[`~!@$%^&*()|+\=?;:'".<>\{\}\[\]\\\/]/gi, '')
    }
    setState({ ...state, [name]: value })
  }

  const handleSave = (e) => {
    e.preventDefault();
    let error = {}
    if (state.FirstName == '') error.FirstName = 'Please Enter First Name'
    if (state.LastName == '') error.LastName = 'Please Enter Last Name'
    if (!state.Email || state.Email == '') error.Email = 'Please Enter E-mail ID'
    if (state.Email && !CommonFunction.validateEmail(state.Email)) error.Email = 'Please Enter Valid E-mail ID'
    if (state.PhoneNo == '') error.PhoneNo = 'Please Enter Mobile Number'
    if (state.PhoneNo && state.PhoneNo.length < 10) error.PhoneNo = 'Please input a valid 10 digit mobile number'
    if (state.Message == '') error.Message = 'Please Enter Message'
    if (state.Subject == '') error.Subject = 'Please Enter Subject'
    if (state.Captcha == '') error.Captcha = 'Please Enter Captcha'
    setError(error)
    if (!Object.keys(error).length) {
      dispatch(actions.CommonActions.showLoader(true))
      const mailFormData = new FormData();
      mailFormData.append('FirstName', state.FirstName);
      mailFormData.append('LastName', state.LastName);
      mailFormData.append('Email', state.Email);
      mailFormData.append('PhoneNo', state.PhoneNo);
      mailFormData.append('Message', state.Message);
      mailFormData.append('Subject', state.Subject);
      mailFormData.append('Address', state.Address);
      mailFormData.append('Captcha', state.Captcha);
      mailFormData.append('Guid', capthadata ? capthadata.Guid : '');
      mailFormData.append('Country', '');
      Array.from({ length: 5 }).forEach((_, index) => {
        let selectedFile = files[index];
        mailFormData.append(`File${index + 1}`, selectedFile && selectedFile.file ? selectedFile.file : null);
      });
     
      dispatch(actions.ServiceAction.InsertMailQuestionFormDetails(mailFormData, (res) => {
        if (res.IsSuccess) {
          setRefNum(res && res.Entity ? res.Entity : '')
          setImmediate(() => {
            PopUp(true, 'QuestionFormModalID')
          })
          setState(initialState)
          setFiles(initialButton)
          GetCaptchaDetails();
        } else {
          toast.error(res.Message);
        }
        dispatch(actions.CommonActions.showLoader(false))
      }))
    }
  }
  return (
    <>
      <Loader showLoader={showLoader} loaderData={loaderData} />
      <Helmet title={metaData && metaData.length && metaData[0].PageTitle || process.env.REACT_APP_MATATAG_TITLE_MAILQESTIONGENERAL_NPS} data={metaData} defaultName={process.env.REACT_APP_MATATAG_NAME} defaultContent={process.env.REACT_APP_MATATAG_CONTENT_MAILQESTIONGENERAL_NPS} titleWithoutSuffix={true} />
      <div className='container pt-5 mailquestiongeneral'>
        <h2 data-font-size-mo="20" data-font-size-pc="32" className='mb-3 pb-2 bb-1'>Customer Support</h2>
        <div className="row ">
          <div className="col-md-4 col-xs-12 mb-4 mt-3">
            <div className="material-input">
              <input type="text" className={`material-input-field ${error.FirstName ? 'is-invalid' : ''}`} name="FirstName" id="f-name" placeholder=" " autoComplete="off" onChange={handleInput} value={state.FirstName} maxLength='50' />
              <label htmlFor="f-name">First Name*</label>
            </div>
            <div className="invalid-feedback d-block">
              {error.FirstName || ''}
            </div>
          </div>
          <div className="col-md-4 col-xs-12 mb-4 mt-3">
            <div className="material-input">
              <input type="text" className={`material-input-field ${error.LastName ? 'is-invalid' : ''}`} name="LastName" id="l-name" placeholder=" " autoComplete="off" onChange={handleInput} value={state.LastName} maxLength='50' />
              <label htmlFor="l-name">Last Name*</label>
            </div>
            <div className="invalid-feedback d-block">
              {error.LastName || ''}
            </div>
          </div>
          <div className="col-md-4 col-xs-12 mb-4 mt-3">
            <div className="material-input">
              <input type="text" className={`material-input-field ${error.Email ? 'is-invalid' : ''}`} name="Email" id="email-id" placeholder=" " onChange={handleInput} autoComplete="off" value={state.Email} maxLength='50' />
              <label htmlFor="email-id">E-mail ID*</label>
            </div>
            <div className="invalid-feedback d-block">
              {error.Email || ''}
            </div>
          </div>
          <div className="col-md-4 col-xs-12 mb-4 mt-3">
            <div className="material-input">
              <input type="text" className={`material-input-field ${error.PhoneNo ? 'is-invalid' : ''}`} name="PhoneNo" id="phone-no" placeholder=" " onChange={handleInput} autoComplete="off" value={state.PhoneNo}/>
              <label htmlFor="phone-no">Mobile Number*</label>
            </div>
            <div className="invalid-feedback d-block">
              {error.PhoneNo || ''}
            </div>
          </div>
          <div className="col-md-8 col-xs-12 mb-4 mt-3">
            <div className="material-input">
              <input type="text" className="material-input-field" name="Address" id="address" placeholder=" " onChange={handleInput} autoComplete="off" value={state.Address} maxLength='100' />
              <label htmlFor="address">Address</label>
            </div>
          </div>
          <div className="col-md-12 col-xs-12 mb-4 mt-3">
            <div className="material-input">
              <input type="text" className="material-input-field" name="Subject" id="subject" placeholder=" " onChange={handleInput} autoComplete="off" value={state.Subject} maxLength='100' />
              <label htmlFor="subject">Subject*</label>
            </div>
            <div className="invalid-feedback d-block">
              {error.Subject || ''}
            </div>
          </div>
          <div className="col-md-12 col-xs-12 mb-4 mt-3">
            <div className='color-8a'>Message*</div>

            <div className="material-input text-area">
              <textarea
                type="text"
                className={`material-input-field ${error.Message ? 'is-invalid' : ''}`}
                name="Message"
                rows="2"
                maxLength="400"
                value={state.Message || ''}
                onChange={handleInput}
              ></textarea>
            </div>
            <div className="invalid-feedback d-block">
              {error.Message || ''}
            </div>
          </div>
          {/* <div className="col-md-6 col-xs-12 mb-4 mt-3">
            <MultipleInputFile {...props} files={files} setFiles={setFiles} fileSize={1} acceptFile=".pdf, .jpg, .jpeg" numberOfFile={5} />
          </div> */}
          <div className="col-md-6 col-xs-12 mb-4 mt-3">
            <div>Captcha*</div>
            <div className='row'>
              <div className='col-xs-12 col-md-6'>
                <img className="my-2 img-responsive" alt="Generating Captcha...." src={`data:image/jpeg;base64,${capthadata.Captcha}`} />
                <button className='text-underline link-href refresh-captcha-btn' data-font-size-mo="10" data-font-size-pc="14" onClick={GetCaptchaDetails}>Re-Generate</button>
              </div>
              <div className='col-xs-12 col-md-6 mt-4'>
                <div className="material-input pos-relative">
                  <input type="text" className={`material-input-field ${error.Captcha ? 'is-invalid' : ''}`} name="Captcha" id="f-name" placeholder="Please fill the Captcha" autoComplete="off" onChange={handleInput} value={state.Captcha} maxLength='6' />
                </div>
                <div className="invalid-feedback d-block">
                  {error.Captcha || ''}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-xs-12 mb-4 mt-0 text-right">
            <div className="material-input">
              <button className='cta cta--contained cta--black cta--icon' onClick={e => handleSave(e)}>Send</button>
            </div>
          </div>
        </div>
      </div>
      <MailQuestionFormModal RefNum={RefNum} setRefNum={setRefNum} />
    </>
  )
}

export default MailQuestionGeneralNps