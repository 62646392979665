
const InitialData = {
    error: false, msg: '', productList: {}, sidemenu: {}, quicklinks: {}, exploreservice: {}, solutionstips: {}, isLoggedIn: false, selectedProduct: {}, showProductPage: false, showLoader: false, navMenu: 'home', isQrcode: false,
    SelfhelpData: { subCategoryDataList: [], productDataList: [], SHStep: 1, selectedIssues: {}, chatData: [], selectedSupportOption: '', scheduleReqHistory: 'newRequest', error: {}, RescheduleAppData: {}, chatbot: false, isScheduled: false, querySolved: '', SessionID: '', UserMessage: '', chatTypeing: true, category: '' }, AgentScheduleList: [],EmailUsFlag:false
}

const productReducer = (state = InitialData, action) => {
    switch (action.type) {
        case 'init':
            return { error: false, msg: '', productList: {}, sidemenu: {}, quicklinks: {}, exploreservice: {}, solutionstips: {}, isLoggedIn: false, selectedProduct: {}, showProductPage: false, showLoader: false, navMenu: 'home', isQrcode: false, SelfhelpData: {} }
        case 'productList':
            return { ...state, productList: action.payload }
        case 'menu':
            return { ...state, productsolution: action.productsolution, sidemenu: action.sidemenu, quicklinks: action.quicklinks, exploreservice: action.exploreservice, menuData: action.data, solutionstips: action.solutionstips, productSolutionsTips: action.productSolutionsTips, search: action.search, searchLinks: action.searchLinks }
        case 'isLoggedIn':
            return { ...state, isLoggedIn: action.data }
        case 'userProduct':
            return { ...state, userData: action.data }
        case 'selectedProductSolution':
            return { ...state, selectedProductSolution: action.data }
        case 'selectedProduct':
            return { ...state, selectedProduct: action.data }
        case 'showProductPage':
            return { ...state, showProductPage: action.data }
        case 'showLoader':
            return { ...state, showLoader: action.data }
        case 'navMenu':
            return { ...state, navMenu: action.data }
        case 'loaderData':
            return { ...state, loaderData: action.data }
        case 'browserName':
            return { ...state, browserName: action.data }
        case 'SparePartResponse':
            return { ...state, sparepartsDetails: action.data }
        case 'SparePartPrice':
            return { ...state, SpareRepairePriceData: action.data }
        case 'RepairPartsDetails':
            return { ...state, repairpartsPriceDetails: action.data }
        case 'SetSpareRepaireData':
            return { ...state, SpareRepaireData: action.data }
        case 'SetSelfhelpData':
            return { ...state, SelfhelpData: action.data }
        case 'setQrcodeStatus':
            return { ...state, isQrcode: action.data }
        case 'GetProductIssueSubIssue':
            return { ...state, ProductIssueSubIssue: action.data }
        case 'GetTimeSlots':
            return { ...state, AgentTimeSlots: action.data }
        case 'GetPromotionImages':
            return { ...state, PromotionImages: action.data }
        case 'GetAgentScheduleData':
            return { ...state, AgentScheduleList: action.data }
        case 'InsertUserMasterDetails':
            return { ...state, InsertUserMasterDetails: action.data }
        case 'DeleteUserMasterAddress':
            return { ...state, DeleteUserMasterAddress: action.data }
        case 'SetEmailUsFlag':
            return { ...state, EmailUsFlag: action.data }
        case 'GetMetaTag':
            return { ...state, GetMetaTag: action.data }
        default:
            return state
    }
}
export default productReducer