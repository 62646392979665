import jQuery from "jquery";

const hashkey = "your-static-key";

export const PopUp = (flag, id = null, overlay = true) => {
  if (id) {
    if (flag) {
      jQuery(`#${id}`).removeClass("hidden")
      jQuery(`#${id}`).attr("style", "display:block")
      jQuery("#overLay").attr("style", "display:block")
      if(overlay) jQuery(`#overLay`).removeClass("hidden")
    } else {
      jQuery(`#${id}`).addClass("hidden")
      jQuery(`#${id}`).attr("style", "display:none")
      jQuery("#overLay").attr("style", "display:none")
      if(overlay) jQuery(`#overLay`).addClass("hidden")
    }
  } else if (flag) {
    document.querySelector(".modal").classList.remove("hidden");
    document.querySelector(".overlay").classList.remove("hidden");
  } else {
    document.querySelector(".modal").classList.add("hidden");
    document.querySelector(".overlay").classList.add("hidden");
    return;
  }
};

export const ScriptFun = () => {
  ///////////Explore More////////////////
  if (document.querySelector(".explore-more")) {
    document
      .querySelector(".explore-more")
      .addEventListener("click", function () {
        document.querySelector(".modal-explore").classList.remove("hidden");
        document.querySelector(".overlay").classList.remove("hidden");
      });
    document
      .querySelector(".btn--closeExplore")
      .addEventListener("click", function () {
        document.querySelector(".modal-explore").classList.add("hidden");
        document.querySelector(".overlay").classList.add("hidden");
      });
  }

  document.addEventListener("keydown", function (e) {
    if (
      e.key === "Escape" &&
      !document.querySelector(".modal").classList.contains("hidden")
    ) {
      document.querySelector(".modal").classList.add("hidden");
      document.querySelector(".overlay").classList.add("hidden");
    }
  });

  // Go to top scroll
  if (document.querySelector(".scrolltop")) {
    const scrollBtn = document.querySelector(".scrolltop");
    const btnVisibility = () => {
      if (window.scrollY > 200) {
        scrollBtn.style.visibility = "visible";
        scrollBtn.classList.add('show')
      } else {
        scrollBtn.style.visibility = "hidden";
        scrollBtn.classList.remove('show')
      }
    };
    document.addEventListener("scroll", () => {
      btnVisibility();
    });
    scrollBtn.addEventListener("click", () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  }

  ///////////////////////////////////////////
  //Start  Set header Banner image height ///

  // Defining event listener function
  // function displayWindowSize() {
  //   // Get width and height of the window excluding scrollbars
  //   if (window.matchMedia("screen and (min-width: 768px)").matches) {
  //     var clientHeight =
  //       document.querySelector(".get-image-height").offsetHeight;
  //       if(document.querySelector( ".set-search-height"))  document.querySelector( ".set-search-height").style.height = `${clientHeight}px`;
  //   }
  // }
  // Attaching the event listener function to window's resize event
  // window.addEventListener("resize", displayWindowSize);

  // // Calling the function for the first time
  // displayWindowSize();
  //End  Set header Banner image height ///
  ///////////////////////////////////////////
  if (document.querySelector(".explore-more")) {
    document
      .querySelector(".explore-more")
      .addEventListener("click", function () {
        document.querySelector(".modal-explore").classList.remove("hidden");
        document.querySelector(".overlay").classList.remove("hidden");
      });
    document
      .querySelector(".btn--closeExplore")
      .addEventListener("click", function () {
        document.querySelector(".modal-explore").classList.add("hidden");
        document.querySelector(".overlay").classList.add("hidden");
      });
  }
  // btnCloseModalFoo.addEventListener('click', closeModal);

  // btnCloseModal.addEventListener('click', closeModal);
  document.querySelector(".overlay").addEventListener("click", function () {
    document.querySelector(".modal").classList.remove("hidden");
    document.querySelector(".overlay").classList.remove("hidden");
  });

  // Search And Explore

  const exploreButton = document.querySelector(".explore-more");
  const hideButtonExplore = document.querySelector(".hide-box");

  const searchAnything = document.querySelector("#search-kv");
  const searchModal = document.querySelector(
    ".search-kv__popular-searches-wrap"
  );
  const searchWrap = document.querySelector(".search-kv__search-wrap");
  const searchFormWrap = document.querySelector(".search-kv__form-wrap");
  const searchDelete = document.querySelector(".search-kv__search-delete");
  const closeFilterBtn = document.querySelector(
    ".search-kv__search-wrap-close"
  );
  const showExploreBox = function () {
    if (showExploreModal.classList.contains("hidden")) {
      showExploreModal.classList.remove("hidden");
    }
  };
  const hideExploreBox = function () {
    showExploreModal.classList.add("hidden");
  };
  const showExploreModal = document.querySelector(".gnb-search");

  var $block = jQuery(".search-kv__no-searches-result");
  var popularSearches = jQuery(".search-kv__popular-searches-wrap");
  const showSearchBox = function () {
    document.querySelector(
      ".search-kv__popular-searches-wrap"
    ).style.display = "block";
    document.querySelector(".search-kv__search-wrap")
      .classList.add("layer-popup");
    document.querySelector(".search-kv__form-wrap")
      .classList.add("is-active");
    document.querySelector(".search-kv__search-delete").style.display =
      "block";
    $block.show();
  };
  const showSearchBoxOnClick = function () {
    if (!input.value && input.value == '') {
      searchList()
    }
    showSearchBox()
  };
  // Search Filter

  jQuery("#search-kv").keyup(function () {
    // Retrieve the input field text and reset the count to zero
    var filter = jQuery(this).val(),
      count = 0;
    var isMatch = false;

    // Loop through the comment list
    jQuery(".search-kv__searches-list li").each(function () {
      // If the list item does not contain the text phrase fade it out
      if (jQuery(this).text().search(new RegExp(filter, "i")) < 0) {
        jQuery(this).fadeOut();

        // Show the list item if the phrase matches and increase the count by 1
      } else {
        isMatch = true;
        jQuery(this).show();
        count++;
      }
    });
    // popularSearches.show();
    // $block.show();

    if (!isMatch) {
      // jQuery(this).closest('.menu').show()
      $block.show();
      popularSearches.hide();
    } else {
      // jQuery(this).closest('.menu').hide()
      $block.show();
      popularSearches.show();
    }
  });

  jQuery(document).mouseup(function (e) {
    var popular = jQuery(".search-kv__popular-searches-wrap");
    var suggested = jQuery(".search-kv__suggested-searches-wrap");
    var noresult = jQuery(".search-kv__no-searches-result");

    // if the target of the click isn't the container nor a descendant of the container
    if ((!popular.is(e.target) && popular.has(e.target).length === 0) || (!suggested.is(e.target) && suggested.has(e.target).length === 0) || (!noresult.is(e.target) && noresult.has(e.target).length === 0)) {
      //hideFilterBox()
    }
  });

  // close filter
  const hideFilterBox = function () {
    if (document.querySelector(".search-kv__popular-searches-wrap")) {
      document.querySelector(".search-kv__popular-searches-wrap").style.display = "none";
      document.querySelector(".search-kv__search-wrap")
        .classList.remove("layer-popup");
      document.querySelector(".search-kv__form-wrap")
        .classList.remove("is-active");
      document.querySelector(".search-kv__search-delete").style.display =
        "none";
      $block.hide();
    }

  };
  const hideFilterBoxOnClick = function () {
    input.value = ''
    hideFilterBox();
  };
  const searchList = function () {
    var filter = jQuery(this).val(),
      count = 0;
    var isMatch = false;

    // Loop through the comment list
    jQuery(".search-kv__searches-list li").each(function () {
      // If the list item does not contain the text phrase fade it out
      if (jQuery(this).text().search(new RegExp(filter, "i")) < 0) {
        jQuery(this).fadeOut();

        // Show the list item if the phrase matches and increase the count by 1
      } else {
        isMatch = true;
        jQuery(this).show();
        count++;
      }
    });

    if (!isMatch) {
      // jQuery(this).closest('.menu').show()
      $block.show();
      popularSearches.hide();
    } else {
      // jQuery(this).closest('.menu').hide()
      $block.show();
      popularSearches.show();
    }
  }
  //jQuery("#search-kv").focusout(hideFilterBox)

  var input = document.querySelector("#search-kv");
  var popularSearchText = jQuery("#popular");
  if (input) {
    input.addEventListener("keypress", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        let popularValue = popularSearchText.children(":visible").text() ? popularSearchText.children(":visible").text().trim().toLowerCase() : ''
        let inputValue = input.value ? input.value.trim().toLowerCase() : ''
        if(inputValue == popularValue){
          document.getElementById(inputValue).click();
          }
          else{
            window.open(`https://www.samsung.com/in/search/?searchvalue=${input.value}#support`,'_blank')
          }
        //jQuery("#searchUrl").click()
      }
    });
  }
  //Intilization Search
  if (document.querySelector(".search-kv__search-wrap-close")) document.querySelector(".search-kv__search-wrap-close").addEventListener("click", hideFilterBox);
  if (document.querySelector(".search-kv__search-delete")) document.querySelector(".search-kv__search-delete").addEventListener("click", hideFilterBoxOnClick);

  //  closeFilterBtn.addEventListener("click", hideFilterBox);
  //  searchDelete.addEventListener("click", hideFilterBox);

  if (document.querySelector("#search-kv")) {
    document.querySelector("#search-kv").addEventListener("keydown", showSearchBox);
  }

  if (input) input.addEventListener("click", showSearchBoxOnClick);

}

function xorEncryptDecrypt(input, key) {
  let result = [];
  for (let i = 0; i < input.length; i++) {
    result.push(input.charCodeAt(i) ^ key.charCodeAt(i % key.length));
  }
  return String.fromCharCode.apply(null, result);
}
export const Encrypt = (text) => {
  const encrypted = xorEncryptDecrypt(text, hashkey);
  return btoa(encrypted) 
}
export const Decrypt = (text) => {
  const decoded   = atob(text);
  return xorEncryptDecrypt(decoded, hashkey);
}