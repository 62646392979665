import React from 'react'
import { PopUp } from '@Core/Library'
const ConsentModal = (props) => {
  let {  handleTakeConsent } = props
 
  return (
    <>
      <div id="consentPopUp" className="modal terms-conditions">
        <div className="modal-content">
          <div className="modal-body text-left">
            {/* <img
              src="../img/svg-icons/success.svg"
              className="success-icon"
              alt="success"
            /> */}
            <div className='row center-sm bottom-sm'>
              <div className='col-sm-10'>
              <h2 className="form__title padding-top--0 font-700 mb-3 text-left"
              data-font-size-mo="18" data-font-size-pc="28" >
              Marketing offers consent
            </h2>
            {/* <p className='mb-4'>Get started with your samsung account</p> */}
            <p data-font-size-mo="12" className='text-left' data-font-size-pc="16">
            Would you like to receive personalized offers and updates on Samsung products?
            </p>
            <p data-font-size-mo="12" className='text-left' data-font-size-pc="16">
             Please refer to our <a href='https://www.samsung.com/in/info/privacy/' target={'_blank'}>  Privacy policy </a> on data handling and click <a href='https://www.samsung.com/in/microsite/owba/hhp-cdm/cr55/' target={'_blank'}> here </a> to know our marketing channels and opt-out options
            </p>
              </div>
              <div className='col-sm-2 text-center justify-content-vertical'>
              <a className=" cta cta--contained cta--black cta--icons m-2" href="#" onClick={e => handleTakeConsent(e, true)} > Yes</a>
            <a className=" cta cta--outlined cta--black  m-2" href="#" onClick={e => handleTakeConsent(e, false)}> Later </a>
                </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  )
}

export default ConsentModal
