import React, { PureComponent } from "react";
import { bool, func, node, string } from "prop-types";
// import throwGlobalError from '@lite/utils/dist/throwGlobalError.js';

class ErrorBoundary extends PureComponent {
  static propTypes = {
    children: node,
    debug: bool,
    errorMessage: string,
    render: func,
  };

  static defaultProps = {
    children: null,
    debug: false,
    errorMessage: "",
    render: null,
  };

  state = {
    hasError: false,
  };

  defaultRender = () => {
    const { errorMessage } = this.props;

    const message = errorMessage || "Sorry, something went wrong.";

    return <div>{message}</div>;
  };

  componentDidCatch(error, info) {
    const { debug } = this.props;

    if (debug) {
      console.groupCollapsed(`Error occured!`);
      console.log("Error:", error);
      console.log("Info:", info);
      console.groupEnd();
    }

    // Only `captureException` if `error` is instance of Error; not plain object that has same structure as
    // Error object. Sentry will throw "non-error exception captured with keys" if it captured `error` that is not
    // instance of Error.
    if (window.Raven && error instanceof Error) {
      window.Raven.captureException(error, { extra: info });
    }

    // if (_TEST_) {
    //   throwGlobalError(error);
    // }

    this.setState({ hasError: true });
  }

  render() {
    const { children, render } = this.props;
    const { hasError } = this.state;
    const renderError = render || this.defaultRender;

    return hasError ? renderError() : children;
  }
}

export default ErrorBoundary;