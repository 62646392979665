import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import actions from '../../../Redux/Action'
import { PopUp } from '../../../@Core/Library';
import { toast } from 'react-toastify';
import CommonFunction from '@Core/Components/CommonFunction';

const VoucherCodeModal = (props) => {
  const dispatch = useDispatch();
  let {offerProduct , userData} = props;
  let userDetails = userData && userData.userData && userData.userData.userDetails && userData.userData.userDetails ? userData.userData.userDetails : {};
  const [voucherData, setVoucherData] = useState({});
  let [couponStatus, setCouponStatus] = useState("spinner");

  useEffect(() => {
    dispatch(actions.ServiceAction.GetGenerateOfferCode(props.token, {"SerialNo": offerProduct.SERIAL_NO }, (res) => {
      if (res.IsSuccess) {
        setVoucherData(res.Entity);
        if(res.Entity && Object.keys(res.Entity).length>0 && !(res.Entity.AlreadyGenerated)){
          setCouponStatus("refNoGenerate");
        }
        else if(res.Entity && Object.keys(res.Entity).length>0 && res.Entity.AlreadyGenerated){
          setCouponStatus("redeemedCode");
        }  
      } else {
        toast.error(res.Message);
      }
      dispatch(actions.CommonActions.showLoader(false))
    }))
  }, [])


  const handleClose = (e) => {
    e && e.preventDefault();
    PopUp(false, 'voucherModal');
    //props.handleScroll();
    props.handleVoucherModal('','')
  }

  return (
    <div className="deepLinking vouchercode-popup">   
      <div className="modal-content hidden" id='voucherModal'>
        <button className='diypopup-close' onClick={handleClose}>
          <img src="img/diy/close.svg" />
        </button>
        <div className="modal-body">
          <div className="row center-xs">

           {couponStatus =="spinner" && <div className="col-md-12 col-xs-12 mt-3">
              <div className='mb-2'><img src="img/srpopup/loading.gif" alt="authentication" className='img-responsive' width='80px' /></div>
              <h2
                className="form__title padding-top--0 text-center"
                data-font-size-mo={18}
                data-font-size-pc={24}
              >
               Please Wait
              </h2>
              <p>Code is getting generated</p>
            </div>} 

           {couponStatus =="refNoGenerate" && <div className="col-md-12 col-xs-12 mt-3">
             <div className='authimage-circle'><img src="img/srpopup/authcode.png" alt="authentication" className='img-responsive' /></div>
              <h2 className="form__title padding-top--0 text-center"
                data-font-size-mo={18}
                data-font-size-pc={24}>
                Authorization Code
              </h2>
              { userDetails && userDetails.mobileNumber &&
              <p>We have sent <span className='font-600'>One time authorization code</span> to your registered mobile number  <span className='font-600'>+91-{CommonFunction.mobileFormatter(userDetails.mobileNumber.toString())}</span></p>
              }
              <p className='auth-code-bx'>Authorization code <span>{voucherData.ReferenceNumber}</span></p>
              <p>You can show this code at any Samsung authorized center & avail benefit of one time screen guard replacement</p>
            </div>} 
            
            {couponStatus =="redeemedCode" && <div className="col-md-12 col-xs-12 mt-3">
            <div className='mb-2'><img src="img/srpopup/open-mail.png" alt="authentication" className='img-responsive' width='80px' /></div>
              <p>You have already generated the code.</p>
              <p className='auth-code-bx'>Authorization code <span>{voucherData.ReferenceNumber}</span></p>
            </div>}
          
          </div>
           </div>
        {/* <div className="modal-footer">
          <a className="cta cta--outlined cta--black close-modal" href="" onClick={handleClose} >Close</a>
        </div> */}

      </div>
    </div>
  )
}

export default VoucherCodeModal
