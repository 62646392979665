import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import introJs from 'intro.js';
import 'intro.js/introjs.css'
import actions from "../../../Redux/Action";
import SearchContainer from "../../CommnPages/SearchContainer";
import MyProductsComp from "../../CommnPages/MyProductsComp";
import ProductSolutionsComp from "../../CommnPages/ProductSolutionsComp";
import QuickLinksComp from "../../CommnPages/QuickLinksComp";
//import SolutionTipsComp from "../../CommnPages/SolutionTipsComp";
import ContactUs from "../../CommnPages/ContactUs";
import ExploreMorePopUp from "../../CommnPages/ExploreMorePopUp";
import AddPoductMsgModal from "../../CommnPages/Modal/AddPoductMsgModal";
//import ModifyProductModal from "../../CommnPages/Modal/ModifyProductModal";
import VoucherCodeModal from "../../CommnPages/Modal/VoucherCodeModal";
import SupportSystem from "../../CommnPages/SupportSystem";
//import OneTimeBanner from "../../CommnPages/OneTimeBanner";
import PromotionImages from "../../CommnPages/PromotionImages";
import { PopUp, ScriptFun } from '@Core/Library';
import { set, get } from '@Core/LocalStorage'
import Helmet from "@Core/Components/Helmet";



class MainDeskComp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            change: true,
            modalState: false,
            searchTerm: "",
            error: {},
            isLoder: true,
            value: '0'
        };
        props.CommonActions.navMenu('home');
    }

    componentDidMount() {
        if (!this.props.PromotionImages) this.props.CommonActions.GetPromotionImages();
        this.props.handleScroll()
        this.onloadLogging()
        ScriptFun();
        let introComplete = get('introComplete') || false
        setTimeout(() => {
            if ((this.props.menu && Object.keys(this.props.menu).length > 0) && !introComplete && !this.props.isLoggedIn) {
                introJs().setOptions({
                    steps: [{
                        title: 'Welcome',
                        intro: ' Hi!<br/> Would you like a quick tour?  <button id="skipGuid" class="skipGuid" >No, I’m Good</button>'
                    },
                    {
                        title: '(2/5) Samsung Account Sign-in',
                        element: document.querySelector('.signinguid'),
                        intro: 'Get information customized to your products once you sign-in.'
                    },
                    {
                        title: '(3/5) Product Solution',
                        element: document.querySelector('.productsolution'),
                        intro: 'Select your product category.'
                    },
                    {
                        title: '(4/5) Quick Links',
                        element: document.querySelector('.quicklinksguid'),
                        intro: 'Easy access to our top searched services.'
                    },
                    {
                        title: '(5/5) Need to Contact Us?',
                        element: document.querySelector('.contactguid'),
                        intro: 'Get in touch with a product expert.'
                    }],
                    // nextLabel:'>',
                    // prevLabel:'<',
                    hidePrev: true,
                    exitOnEsc: false,
                    exitOnOverlayClick: false,
                    KeyboardNavigation: true,
                    dontShowAgain: false,
                    tooltipClass: 'customTooltip',
                    showBullets: false,
                    scrollToElement: true,
                    scrollTo: 'tooltip',
                }).oncomplete(() => {
                    this.props.handleScroll()
                    set('introComplete', true)
                }).onexit(() => {
                    this.props.handleScroll()
                    set('introComplete', true)
                }).start();

                document.querySelector(".skipGuid").addEventListener("click", function () {
                    introJs().exit()
                    set('introComplete', true)
                })
            }

        }, 0)
    }
    handleProductSol = (e, childMenu) => {
        e.preventDefault();
        this.props.CommonActions.setProductsSolutionChild(childMenu);
        this.props.handlePageRoute('product', childMenu)
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

    };
    handleProductDetails = (e, prod) => {
        e.preventDefault();
        this.props.CommonActions.setProductsDetails(prod);
        this.props.handlePageRoute('product', prod)
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

    };
    handleNavManu = (e, manu) => {
        e && e.preventDefault();
        this.props.CommonActions.navMenu(manu);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
    onloadLogging = () => {
        let data = {
            LinkName: "Home",
            ChildMenuRedirectURL: '/'
        }
        this.props.handleLogging('', data, null, 'Onload Home',)
    }

    handleVoucherModal = (e, name, data = {}) => {
        e && e.preventDefault();
        this.setState({ selectedModal: name, offerProduct: data }, () => {
            PopUp(true, name)
        })
    }
    render() {
        let metaData = this.props.MetaTag && this.props.MetaTag.length>0 &&  this.props.MetaTag.filter(item=>item.PageName == process.env.REACT_APP_MATATAG_PAGENAME_HOME).length ? this.props.MetaTag.filter(item=>item.PageName == process.env.REACT_APP_MATATAG_PAGENAME_HOME) : []
        return (
            <React.Fragment>
                <Helmet title={metaData && metaData.length && metaData[0].PageTitle || process.env.REACT_APP_MATATAG_TITLE_HOME} data={metaData} defaultName={process.env.REACT_APP_MATATAG_NAME} defaultContent={process.env.REACT_APP_MATATAG_CONTENT_HOME} />
                {/* <!-- MAIN CONTENT  --> */}
                <SearchContainer {...this.props} {...this.state} handleLogging={this.props.handleLogging} />
                {this.props.isLoggedIn ?
                    <MyProductsComp {...this.props} SourceView={'home'} handleProductDetails={this.handleProductDetails} handleLogging={this.props.handleLogging} homeView={true} handleVoucherModal={this.handleVoucherModal} />
                    : <ProductSolutionsComp  {...this.props} handleProductSol={this.handleProductSol} handleLogging={this.props.handleLogging} />
                }
                {/* {this.props.isLoggedIn && <OneTimeBanner {...this.props}/>} */}
                {/* <!-- Quick Links for Mobile--> */}
                <QuickLinksComp {...this.props} productPage={false} handleLogging={this.props.handleLogging} />

                <SupportSystem {...this.props} handleLogging={this.props.handleLogging} productPage={false} />
                {/* <!-- Help Me to Choose --> */}
                {/* <SolutionTipsComp {...this.props} handleLogging={this.props.handleLogging} /> */}
                {/* <!-- Contact for Desktop --> */}
                {!this.props.isMobile && <ContactUs {...this.props} handleLogging={this.props.handleLogging} />}

                {this.props.PromotionImages && this.props.PromotionImages.length > 0 ? <PromotionImages {...this.props} /> : ''}
                {/* <!-- ExploreMore Popup  --> */}
                <ExploreMorePopUp {...this.props} handleLogging={this.props.handleLogging} />
                {this.props.isLoggedIn && this.state.selectedModal == 'voucherModal' && <VoucherCodeModal {...this.props} {...this.state} handleVoucherModal={this.handleVoucherModal} />}

                <AddPoductMsgModal {...this.props} />
                {/* <ModifyProductModal {...this.props} /> */}
                {/* <!-- Footer Spacer --> */}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        menu: state.productReducer.menuData,
        MetaTag: state.productReducer.GetMetaTag,
        productsolution: state.productReducer.productsolution,
        quicklinks: state.productReducer.quicklinks,
        exploreservice: state.productReducer.exploreservice,
        sidemenu: state.productReducer.sidemenu,
        solutionstips: state.productReducer.solutionstips,
        isLoggedIn: state.productReducer.isLoggedIn,
        selectedProductSolution: state.productReducer.selectedProductSolution,
        selectedProduct: state.productReducer.selectedProduct,
        userData: state.productReducer.userData,
        productSolutionsTips: state.productReducer.productSolutionsTips,
        search: state.productReducer.search,
        showLoader: state.productReducer.showLoader,
        searchLinks: state.productReducer.searchLinks,
        navMenu: state.productReducer.navMenu,
        loaderData: state.productReducer.loaderData,
        browserName: state.productReducer.browserName,
        PromotionImages: state.productReducer.PromotionImages,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        CommonActions: bindActionCreators(actions.CommonActions, dispatch),
    };
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MainDeskComp)
);
